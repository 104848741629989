// CLICK AND COLLECT
export const CLEAR_COLLECTION_POINT_SELECTION = 'CLEAR_COLLECTION_POINT_SELECTION';
export const FIND_COLLECTION_POINT = 'FIND_COLLECTION_POINT';
export const GET_COLLECTION_POINT_DETAILS = 'GET_COLLECTION_POINT_DETAILS';
export const SELECT_COLLECTION_POINT = 'SELECT_COLLECTION_POINT';
export const SET_FIND_COLLECTION_POINT_ERROR = 'SET_FIND_COLLECTION_POINT_ERROR';
export const SET_COLLECTION_POINT_VIEW_TYPE = 'SET_COLLECTION_POINT_VIEW_TYPE';
export const NEW_COLLECTION_POINT_SEARCH = 'NEW_COLLECTION_POINT_SEARCH';
export const GET_COLLECTION_POINT_DATES = 'GET_COLLECTION_POINT_DATES';
export const GET_SAVED_COLLECTION_POINTS = 'GET_SAVED_COLLECTION_POINTS';
export const SET_GEO_LOCATION_SEARCH_ACTIVE_STATUS = 'SET_GEO_LOCATION_SEARCH_ACTIVE_STATUS';
export const GOOGLE_MAPS_SCRIPT_LOADED = 'GOOGLE_MAPS_SCRIPT_LOADED';
export const GOOGLE_MAPS_SCRIPT_LOADING = 'GOOGLE_MAPS_SCRIPT_LOADING';
export const CHANGE_COLLECTION_POINT = 'CHANGE_COLLECTION_POINT';

// ADDRESS WITH SEARCH STATE IDS
export const DELIVERY_ADDRESS = 'DELIVERY_ADDRESS';
export const BILLING_ADDRESS = 'BILLING_ADDRESS';
export const RESIDENTIAL_ADDRESS = 'RESIDENTIAL_ADDRESS';
export const MYJL_ADDRESS = 'MYJL_ADDRESS';
export const ADDRESS_BOOK_ADDRESS = 'ADDRESS_BOOK_ADDRESS';

// ADDRESS WITH SEARCH ACTIONS
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const RESET_ADDRESS_SEARCH = 'RESET_ADDRESS_SEARCH';
export const ENTER_MANUAL_ADDRESS = 'ENTER_MANUAL_ADDRESS';

// ANALYTICS
export const ANALYTICS_PAYMENT_SHOW_3D_SECURE = '@@analytics/PAYMENT_SHOW_3D_SECURE';
export const ANALYTICS_SUBMIT_ORDER_ERROR = '@@analytics/SUBMIT_ORDER_ERROR';
export const ANALYTICS_PAGE_NOT_FOUND = '@@analytics/PAGE_NOT_FOUND';
export const ANALYTICS_FORCE_UPDATE_SYNC_ERRORS = '@@analytics/FORCE_UPDATE_SYNC_ERRORS';
export const ANALYTICS_FIND_COLLECTION_POINT_FAILED = '@@analytics/FIND_COLLECTION_POINT_FAILED';
export const ANALYTICS_COLLECTION_POINT_SUCCESS = '@@analytics/COLLECTION_POINT_SUCCESS';
export const ANALYTICS_COLLECTION_POINT_DOWN = '@@analytics/COLLECTION_POINT_DOWN';
export const ANALYTICS_COLLECTION_POINT_CUT_OFF_MODAL_SHOWN =
  '@@analytics/ANALYTICS_COLLECTION_POINT_CUT_OFF_MODAL_SHOWN';
export const ANALYTICS_LEAVE_CHECKOUT_MODAL_SHOWN = '@@analytics/ANALYTICS_LEAVE_CHECKOUT_MODAL_SHOWN';
export const ANALYTICS_MY_JL_JOIN_MODAL_OPEN = '@@analytics/MY_JL_JOIN_MODAL_OPEN';
export const ANALYTICS_DELIVERY_OPTIONS = '@@analytics/DELIVERY_OPTIONS';
export const ANALYTICS_MARKETING_OPT_IN = '@@analytics/ANALYTICS_MARKETING_OPT_IN';
export const ANALYTICS_SAVE_GUEST_ORDER = '@@analytics/ANALYTICS_SAVE_GUEST_ORDER';
export const ANALYTICS_SAVE_GUEST_ORDER_SUCCESS = '@@analytics/ANALYTICS_SAVE_GUEST_ORDER_SUCCESS';
export const ANALYTICS_JOIN_MY_JOHN_LEWIS = '@@analytics/JOIN_MY_JOHN_LEWIS';
export const ANALYTICS_JOIN_MY_JOHN_LEWIS_CANCELLED = '@@analytics/JOIN_MY_JOHN_LEWIS_CANCELLED';
export const ANALYTICS_CREATE_ACCOUNT_CANCELLED = '@@analytics/CREATE_ACCOUNT_CANCELLED';
export const ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED = '@@analytics/CREATE_ACCOUNT_DETAILS_NOT_SAVED';
export const ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED = '@@analytics/CREATE_ACCOUNT_ORDER_NOT_LINKED';
export const ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED = '@@analytics/CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED';
export const ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED =
  '@@analytics/CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED';
export const ANALYTICS_FIND_COLLECTION_POINT_WITH_GEO_LOCATION =
  '@@analytics/FIND_COLLECTION_POINT_WITH_GEO_LOCATION';
export const ANALYTICS_DELIVERY_INSTRUCTIONS_FOCUSED = '@@analytics/DELIVERY_INSTRUCTIONS_FOCUSED';
export const ANALYTICS_DELIVERY_INSTRUCTIONS_SELECTED = '@@analytics/DELIVERY_INSTRUCTIONS_SELECTED';
export const ANALYTICS_GET_COLLECTION_POINT_DETAILS = '@@analytics/GET_COLLECTION_POINT_DETAILS';
export const ANALYTICS_SET_SUBMIT_FAILED = '@@analytics/SET_SUBMIT_FAILED';
export const ANALYTICS_OUT_OF_STOCK_MODAL_OPEN = '@@analytics/ANALYTICS_OUT_OF_STOCK_MODAL_OPEN';
export const ANALYTICS_PARTNER_DISCOUNT_UNAVAILABLE_MODAL_OPEN =
  '@@analytics/ANALYTICS_PARTNER_DISCOUNT_UNAVAILABLE_MODAL_OPEN';
export const ANALYTICS_EDIT_BASKET_MODAL_OPEN = '@@analytics/ANALYTICS_EDIT_BASKET_MODAL_OPEN';
export const ANALYTICS_AMEND_BASKET_MODAL_OPEN = '@@analytics/ANALYTICS_AMEND_BASKET_MODAL_OPEN';
export const ANALYTICS_SHOW_AGE_CHECK_MODAL = '@@analytics/ANALYTICS_SHOW_AGE_CHECK_MODAL';
export const ANALYTICS_BATCH_ERROR = '@@analytics/ANALYTICS_BATCH_ERROR';
export const ANALYTICS_BATCH_ERROR_ITEMS_REMOVED = '@@analytics/ANALYTICS_BATCH_ERROR_ITEMS_REMOVED';
export const ANALYTICS_PLACE_ORDER_AND_PAY_CLICK = '@@analytics/ANALYTICS_PLACE_ORDER_AND_PAY_CLICK';
export const ANALYTICS_PLACE_ORDER_AND_PAY_CLICK_START = '@@analytics/ANALYTICS_PLACE_ORDER_AND_PAY_CLICK_START';
export const ANALYTICS_OPEN_APPLE_PAY_PAYMENT_SHEET = '@@analytics/ANALYTICS_OPEN_APPLE_PAY_PAYMENT_SHEET';
export const ANALYTICS_OPEN_APPLE_PAY_EXPRESS_PAYMENT_SHEET = '@@analytics/ANALYTICS_OPEN_APPLE_PAY_EXPRESS_PAYMENT_SHEET';
export const ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK = '@@analytics/ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK';
export const ANALYTICS_EXPAND_BASKET_BUTTON_CLICKED = '@@analytics/ANALYTICS_EXPAND_BASKET_BUTTON_CLICKED';
export const ANALYTICS_GIFT_RECEIPT_SELECTED = '@@analytics/ANALYTICS_GIFT_RECEIPT_SELECTED';
export const ANALYTICS_GIFT_MESSAGE_POPULATED = '@@analytics/ANALYTICS_GIFT_MESSAGE_POPULATED';
export const ANALYTICS_GIFT_OPTIONS_CHANGE_TO_PREPAID = '@@analytics/ANALYTICS_GIFT_OPTIONS_CHANGE_TO_PREPAID';
export const ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED = '@@analytics/ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED';
export const ANALYTICS_PAYMENT_OPTION_SELECTED = '@@analytics/ANALYTICS_PAYMENT_OPTION_SELECTED';
export const ANALYTICS_PREPAID_GIFTCARD_MESSAGE = '@@analytics/ANALYTICS_PREPAID_GIFTCARD_MESSAGE';
export const ANALYTICS_SINGLE_SIGN_ON_LOGIN = '@@analytics/ANALYTICS_SINGLE_SIGN_ON_LOGIN';
export const ANALYTICS_VOUCHER_UNDERSPEND_MODAL_OPEN = '@@analytics/ANALYTICS_VOUCHER_UNDERSPEND_MODAL_OPEN';
export const ANALYTICS_VOUCHER_UNDERSPEND_REMOVED = '@@analytics/ANALYTICS_VOUCHER_UNDERSPEND_REMOVED';
export const ANALYTICS_PUT_DELIVERY_DETAILS = '@@analytics/ANALYTICS_PUT_DELIVERY_DETAILS';
export const ANALYTICS_SAVE_NEW_CARD_CHECKED = '@@analytics/ANALYTICS_SAVE_NEW_CARD_CHECKED';
export const ANALYTICS_SAVE_NEW_CARD_UNCHECKED = '@@analytics/ANALYTICS_SAVE_NEW_CARD_UNCHECKED';
export const ANALYTICS_ORDER_COMPLETED_WITH_SAVED_CARD = '@@analytics/ANALYTICS_ORDER_COMPLETED_WITH_SAVED_CARD';
export const ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA = 'ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA';
export const ANALYTICS_AUTH0_REGISTRATION_CALLBACK_SUCCESS = '@@analytics/ANALYTICS_AUTH0_REGISTRATION_CALLBACK_SUCCESS';
export const ANALYTICS_SHOW_COLLECTION_HELP = '@@analytics/ANALYTICS_SHOW_COLLECTION_HELP';
export const ANALYTICS_SHOW_COLLECTION_HELP_REMOVE = '@@analytics/ANALYTICS_SHOW_COLLECTION_HELP_REMOVE';
export const ANALYTICS_SHOW_PERSONALISATION_HELP = '@@analytics/ANALYTICS_SHOW_PERSONALISATION_HELP';
export const ANALYTICS_SHOW_PERSONALISATION_CONFIRMATION = '@@analytics/ANALYTICS_SHOW_PERSONALISATION_CONFIRMATION';
export const ANALYTICS_UPDATE_PAGEINFO = '@@analytics/ANALYTICS_UPDATE_PAGEINFO';
export const ANALYTICS_ADD_NEW_CARD_CLICK = '@@analytics/ANALYTICS_ADD_NEW_CARD_CLICK';
export const ANALYTICS_SAVED_CARD_DETAILS_TOGGLED = '@@analytics/ANALYTICS_SAVED_CARD_DETAILS_TOGGLED';

// APP
export const SET_ACTIVE_TOOLTIP_ID = 'SET_ACTIVE_TOOLTIP_ID';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SESSION_REFRESH = 'SESSION_REFRESH';
export const SHOW_DISABLE_SITE_SPINNER = 'SHOW_DISABLE_SITE_SPINNER';
export const HIDE_DISABLE_SITE_SPINNER = 'HIDE_DISABLE_SITE_SPINNER';
export const SHOW_EMPTY_BASKET_NOTIFICATION = 'SHOW_EMPTY_BASKET_NOTIFICATION';
export const LAZY_COMPONENT_LOADING = 'LAZY_COMPONENT_LOADING';
export const LEAVE_CHECKOUT = 'LEAVE_CHECKOUT';
export const CLOSE_LEAVE_CHECKOUT_MODAL = 'CLOSE_LEAVE_CHECKOUT_MODAL';
export const ANNOUNCE_SESSION_EXPIRED = 'ANNOUNCE_SESSION_EXPIRED';
export const ANNOUNCE_SESSION_EXPIRING = 'ANNOUNCE_SESSION_EXPIRING';
export const OPEN_SESSION_ENDED_MODAL = 'OPEN_SESSION_ENDED_MODAL';
export const CLOSE_SESSION_ENDED_MODAL = 'CLOSE_SESSION_ENDED_MODAL';
export const SHOW_MORE_SHOPS = 'SHOW_MORE_SHOPS';
export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const SET_TOAST_HAS_SHOWN = 'SET_TOAST_HAS_SHOWN';
export const SET_FAKE_SESSION_ENDED = 'SET_FAKE_SESSION_ENDED';

// BASKET
export const REMOVE_BASKET_ITEMS = 'REMOVE_BASKET_ITEMS';
export const BASKET_ITEM_OUT_OF_STOCK = 'BASKET_ITEM_OUT_OF_STOCK';
export const HANDLE_EDIT_BASKET_QUANTITY_ERROR = 'HANDLE_EDIT_BASKET_QUANTITY_ERROR';
export const REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET = 'REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET';
export const CLOSE_EDIT_BASKET_MODAL = 'CLOSE_EDIT_BASKET_MODAL';

// BFF
export const HAND_OVER_RESET = 'HAND_OVER_RESET';
export const HAND_OVER = 'HAND_OVER';
export const APPS_GUEST_HANDOVER = 'APPS_GUEST_HANDOVER';// BFF relies on this value,  don't change
export const APPS_AUTHENTICATED_HANDOVER = 'APPS_AUTHENTICATED_HANDOVER';// BFF relies on this value, don't change
export const APPS_REFRESH_ACCESS_TOKEN_REQUESTED = 'APPS_REFRESH_ACCESS_TOKEN_REQUESTED';
export const APPS_ACCESS_TOKEN_ERROR = 'APPS_ACCESS_TOKEN_ERROR';
export const SET_FAKE_SESSION_EXPIRY = 'SET_FAKE_SESSION_EXPIRY';

// ORDER CONFIRMATION
export const GET_ORDER_CONFIRMATION_PAGE = 'GET_ORDER_CONFIRMATION_PAGE';
export const GET_MINIMAL_ORDER_CONFIRMATION_PAGE = 'GET_MINIMAL_ORDER_CONFIRMATION_PAGE';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';

// DELIVERY
export const GET_DELIVERY_PAGE = 'GET_DELIVERY_PAGE';
export const POST_DELIVERY_PAGE = 'POST_DELIVERY_PAGE';
export const POST_DELIVERY_PAGE_NO_ACTION = 'POST_DELIVERY_PAGE_NO_ACTION';
export const GET_DELIVERY_METHODS = 'GET_DELIVERY_METHODS';
export const RESET_DELIVERY_METHODS = 'RESET_DELIVERY_METHODS';
export const PUT_DELIVERY_DETAILS = 'PUT_DELIVERY_DETAILS';
export const PUT_CC_DELIVERY_DETAILS = 'PUT_CC_DELIVERY_DETAILS';
export const PUT_CC_DELIVERY_DETAILS_SILENTLY = 'PUT_CC_DELIVERY_DETAILS_SILENTLY';
export const SET_SELECTED_DELIVERY_CHOICE_ID = 'SET_SELECTED_DELIVERY_CHOICE_ID';
export const SET_SHOW_DELIVERY_ADDRESS_FORM = 'SET_SHOW_DELIVERY_ADDRESS_FORM';
export const SET_GIFT_RECEIPT_SELECTED = 'SET_GIFT_RECEIPT_SELECTED';
export const SET_GIFT_RECEIPT_VALUE = 'SET_GIFT_RECEIPT_VALUE';
export const POST_ADDRESS_BOOK_CREATE = 'POST_ADDRESS_BOOK_CREATE';
export const GET_ADDRESS_BOOK = 'GET_ADDRESS_BOOK';
export const SHOW_REMOVE_ADDRESS_OVERLAY = 'SHOW_REMOVE_ADDRESS_OVERLAY';
export const HIDE_REMOVE_ADDRESS_OVERLAY = 'HIDE_REMOVE_ADDRESS_OVERLAY';
export const PUT_ADDRESS_BOOK_UPDATE = 'PUT_ADDRESS_BOOK_UPDATE';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const SET_ADDRESS_BOOK_EDITABLE_ADDRESS = 'SET_ADDRESS_BOOK_EDITABLE_ADDRESS';
export const UPDATE_SAVED_AS_DEFAULT_ADDRESS = 'UPDATE_SAVED_AS_DEFAULT_ADDRESS';
export const PUT_DELIVERY_ADDRESS = 'PUT_DELIVERY_ADDRESS';
export const CHANGE_DELIVERY_ADDRESS = 'CHANGE_DELIVERY_ADDRESS';
export const SET_PARTNER_DISCOUNT = 'SET_PARTNER_DISCOUNT';
export const SET_PARTNER_DISCOUNT_PROCESSING = 'SET_PARTNER_DISCOUNT_PROCESSING';
export const SET_DELIVERY_BATCHES_PROCESSING = 'SET_DELIVERY_BATCHES_PROCESSING';
export const SET_PUT_DELIVERY_DETAILS_SUCCESS = 'SET_PUT_DELIVERY_DETAILS_SUCCESS';
export const EDIT_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS';
export const SET_ACTIVE_MAP_MARKER = 'SET_ACTIVE_MAP_MARKER';
export const SET_ACTIVE_MODAL_MAP_MARKER = 'SET_ACTIVE_MODAL_MAP_MARKER';
export const PUT_ITEM_QUANTITY = 'PUT_ITEM_QUANTITY';
export const PUT_ITEMS_QUANTITIES = 'PUT_ITEMS_QUANTITIES';
export const REBATCH_ORDER = 'REBATCH_ORDER';
export const SET_EDIT_BASKET_BASE_ROUTE = 'SET_EDIT_BASKET_BASE_ROUTE';
export const GET_ITEMS = 'GET_ITEMS';
export const GET_ITEMS_NO_ACTION = 'GET_ITEMS_NO_ACTION';
export const SHOW_ITEM_REMOVE_OVERLAY = 'SHOW_ITEM_REMOVE_OVERLAY';
export const HIDE_ITEM_REMOVE_OVERLAY = 'HIDE_ITEM_REMOVE_OVERLAY';
export const SET_CLICK_COLLECT_COUNTDOWN_VISIBILITY = 'SET_CLICK_COLLECT_COUNTDOWN_VISIBILITY';
export const SET_CLICK_COLLECT_CUT_OFF_PASSED = 'SET_CLICK_COLLECT_CUT_OFF_PASSED';
export const SET_CLICK_COLLECT_CUT_OFF_PASSED_MODAL_VISIBILITY = 'SET_CLICK_COLLECT_CUT_OFF_PASSED_MODAL_VISIBILITY';
export const DELETE_COLLECTION_POINT = 'DELETE_COLLECTION_POINT';
export const SHOW_REMOVE_COLLECTION_POINT_OVERLAY = 'SHOW_REMOVE_COLLECTION_POINT_OVERLAY';
export const HIDE_REMOVE_COLLECTION_POINT_OVERLAY = 'HIDE_REMOVE_COLLECTION_POINT_OVERLAY';
export const SILENTLY_SELECT_COLLECTION_DATE = 'SILENTLY_SELECT_COLLECTION_DATE';
export const THRESHOLD_BAR_KEEP_SHOPPING_CLICK = 'THRESHOLD_BAR_KEEP_SHOPPING_CLICK';
export const THRESHOLD_BAR_SHOWN = 'THRESHOLD_BAR_SHOWN';
export const THRESHOLD_BAR_SHOWN_ON_QUANTITY_EDIT = 'THRESHOLD_BAR_SHOWN_ON_QUANTITY_EDIT';
export const PRICE_PROMISE_GLOBAL_INTERACTION = 'PRICE_PROMISE_GLOBAL_INTERACTION';

export const DELIVERY_ADDRESS_SELECT_ADDRESS = `${DELIVERY_ADDRESS}_${SELECT_ADDRESS}`;
export const BILLING_ADDRESS_SELECT_ADDRESS = `${BILLING_ADDRESS}_${SELECT_ADDRESS}`;
export const HIDE_SELECTED_BILLING_ADDRESS_PREVIEW = 'HIDE_SELECTED_BILLING_ADDRESS_PREVIEW';

export const SET_SELECTED_SLOT_METHOD = 'SET_SELECTED_SLOT_METHOD';
export const SET_SELECTED_DATE_METHOD = 'SET_SELECTED_DATE_METHOD';
export const SET_SELECTED_DATE_METHOD_PAGE = 'SET_SELECTED_DATE_METHOD_PAGE';

// LOGIN
export const GET_LOGIN_PAGE = 'GET_LOGIN_PAGE';
export const LOGIN_CREDS = 'LOGIN_CREDS';
export const LOGIN_RESET = 'LOGIN_RESET';
export const SHOW_BATCHING_FAILURE_MODAL = 'SHOW_BATCHING_FAILURE_MODAL';
export const SHOW_REMOVE_BATCHING_FAILURE_ERROR = 'SHOW_REMOVE_BATCHING_FAILURE_ERROR';

// PAYMENT / ORDERFORM
export const INIT_PAYMENT_PAGE = 'INIT_PAYMENT_PAGE';
export const INIT_PAYMENT_PAGE_NO_ACTION = 'INIT_PAYMENT_PAGE_NO_ACTION';
export const GET_PAYMENT_PAGE = 'GET_PAYMENT_PAGE';
export const GET_PAYMENT_PAGE_NO_ACTION = 'GET_PAYMENT_PAGE_NO_ACTION';
export const APPLY_GIFT_CARD = 'APPLY_GIFT_CARD';
export const APPLY_GIFT_VOUCHER = 'APPLY_GIFT_VOUCHER';
export const GET_GIFTING_PAYMENT_DETAILS = 'GET_GIFTING_PAYMENT_DETAILS';
export const REMOVE_GIFT_OPTION = 'REMOVE_GIFT_OPTION';
export const CARD_PAYMENT = 'CARD_PAYMENT';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const SET_CARD_TYPE = 'SET_CARD_TYPE';
export const CLEAR_CARD_TYPE = 'CLEAR_CARD_TYPE';
export const CARD_TYPE_MISSING = 'CARD_TYPE_MISSING';
export const SUBMIT_PREFERENCES = 'SUBMIT_PREFERENCES';
export const USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS = 'USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS';
export const USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS = 'USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS';
export const USE_DIFFERENT_RESIDENTIAL_ADDRESS = 'USE_DIFFERENT_RESIDENTIAL_ADDRESS';
export const USE_DIFFERENT_BILLING_ADDRESS = 'USE_DIFFERENT_BILLING_ADDRESS';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const SET_POS_CREDIT_APPLICATION_STATUS = 'SET_POS_CREDIT_APPLICATION_STATUS';
export const SET_PAYMENT_FORMS_VALIDATION_FAILED = 'SET_PAYMENT_FORMS_VALIDATION_FAILED';
export const SET_SAVED_PAYMENT_FORMS_VALIDATION_FAILED = 'SET_SAVED_PAYMENT_FORMS_VALIDATION_FAILED';
export const CARD_FORM_VALIDATION_STATUS = 'CARD_FORM_VALIDATION_STATUS';
export const SUBMIT_ORDER_BUTTON_CLICKED = 'SUBMIT_ORDER_BUTTON_CLICKED';
export const SUBMIT_ORDER_BUTTON_NOT_CLICKED = 'SUBMIT_ORDER_BUTTON_NOT_CLICKED';
export const POS_CREDIT_PAYMENT = 'POS_CREDIT_PAYMENT';
export const GET_PAYPAL_CLIENT_TOKEN = 'GET_PAYPAL_CLIENT_TOKEN';
export const GET_PAYPAL_EXPRESS_PAYMENT_REQUEST = 'GET_PAYPAL_EXPRESS_PAYMENT_REQUEST';
export const SET_PAYPAL_SERVICE_UNAVAILABLE = 'SET_PAYPAL_SERVICE_UNAVAILABLE';
export const SET_PAYPAL_PAYLATER_IS_NOT_ELIGIBLE = 'SET_PAYPAL_PAYLATER_IS_NOT_ELIGIBLE';
export const SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS = 'SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS';
export const CREATE_PAYPAL_PAYMENT = 'CREATE_PAYPAL_PAYMENT';
export const AUTHENTICATE_PAYPAL_PAYMENT = 'AUTHENTICATE_PAYPAL_PAYMENT';
export const PAYPAL_PAYMENT_CANCEL = 'PAYPAL_PAYMENT_CANCEL';
export const PAYPAL_PAYMENT_FAILED = 'PAYPAL_PAYMENT_FAILED';
export const PAYPAL_PAYMENT_STARTED = 'PAYPAL_PAYMENT_STARTED';
export const PAYPAL_PAYMENT_ENDED = 'PAYPAL_PAYMENT_ENDED';
export const PAYPAL_STUBS_MODAL_OPEN = 'PAYPAL_STUBS_MODAL_OPEN';
export const PAYPAL_STUBS_MODAL_CLOSE = 'PAYPAL_STUBS_MODAL_CLOSE';
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE';
export const RESET_SUBMIT_ORDER_ERROR = 'RESET_SUBMIT_ORDER_ERROR';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const SET_CARD_PAYMENT_3DSECURE_STATUS = 'SET_CARD_PAYMENT_3DSECURE_STATUS';
export const SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS = 'SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS';
export const SET_CARD_PAYMENT_PREPAID_CANCELLED_STATUS = 'SET_CARD_PAYMENT_PREPAID_CANCELLED_STATUS';
export const SET_CARD_PAYMENT_3DSECURE_INVALID_STATUS = 'SET_CARD_PAYMENT_3DSECURE_INVALID_STATUS';
export const SET_PAYMENT_PROCESSING = 'SET_PAYMENT_PROCESSING';
export const GIFT_OPTIONS_MODAL_CLOSE = 'GIFT_OPTIONS_MODAL_CLOSE';
export const GIFT_OPTIONS_MODAL_OPEN = 'GIFT_OPTIONS_MODAL_OPEN';
export const VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE = 'VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE';
export const GIFT_OPTIONS_CHANGE_OPTION = 'GIFT_OPTIONS_CHANGE_OPTION';
export const CHANGE_PAYMENT_DETAILS = 'CHANGE_PAYMENT_DETAILS';
export const SAVED_CARD_SELECTED = 'SAVED_CARD_SELECTED';
export const APPLE_PAY_SELECTED = 'APPLE_PAY_SELECTED';
export const GOOGLE_PAY_DEFAULT = 'GOOGLE_PAY_DEFAULT';
export const GOOGLE_PAY_ENABLED = 'GOOGLE_PAY_ENABLED';
export const GOOGLE_SCRIPT_LOADING = 'GOOGLE_SCRIPT_LOADING';
export const GOOGLE_SCRIPT_LOADED = 'GOOGLE_SCRIPT_LOADED';
export const GOOGLE_SCRIPT_FAILED = 'GOOGLE_SCRIPT_FAILED';
export const MONETATE_SCRIPT_LOADING = 'MONETATE_SCRIPT_LOADING';
export const MONETATE_SCRIPT_LOADED = 'MONETATE_SCRIPT_LOADED';
export const MONETATE_SCRIPT_FAILED = 'MONETATE_SCRIPT_FAILED';
export const IOVATION_SCRIPTS_LOADING = 'IOVATION_SCRIPTS_LOADING';
export const IOVATION_SCRIPTS_LOADED = 'IOVATION_SCRIPTS_LOADED';
export const IOVATION_SCRIPTS_FAILED = 'IOVATION_SCRIPTS_FAILED';
export const CLEARPAY_SCRIPT_LOADING = 'CLEARPAY_SCRIPT_LOADING';
export const CLEARPAY_SCRIPT_LOADED = 'CLEARPAY_SCRIPT_LOADED';
export const CLEARPAY_SCRIPT_FAILED = 'CLEARPAY_SCRIPT_FAILED';
export const CLEARPAY_MESSAGE_SCRIPT_LOADED = 'CLEARPAY_MESSAGE_SCRIPT_LOADED';
export const SET_CLEARPAY_SERVICE_UNAVAILABLE = 'SET_CLEARPAY_SERVICE_UNAVAILABLE';
export const CLEARPAY_STUBS_MODAL_OPEN = 'CLEARPAY_STUBS_MODAL_OPEN';
export const CLEARPAY_STUBS_MODAL_CLOSE = 'CLEARPAY_STUBS_MODAL_CLOSE';
export const CLEARPAY_SAVE_CARD = 'CLEARPAY_SAVE_CARD';
export const CLEARPAY_ERROR = 'CLEARPAY_ERROR';
export const CLEARPAY_CANCEL = 'CLEARPAY_CANCEL';
export const CLEARPAY_ENABLED_STATUS = 'CLEARPAY_ENABLED_STATUS';

// Klarna
export const KLARNA_CANCEL = 'KLARNA_CANCEL';
export const KLARNA_ENABLED_STATUS = 'KLARNA_ENABLED_STATUS';
export const KLARNA_ERROR = 'KLARNA_ERROR';
export const KLARNA_INITIALISE_ERROR = 'KLARNA_INITIALISE_ERROR';
export const KLARNA_LOAD_COMPLETE = 'KLARNA_LOAD_COMPLETE';
export const KLARNA_PAYMENT_COMPLETED = 'KLARNA_PAYMENT_COMPLETED';
export const KLARNA_PAYMENT_STARTED = 'KLARNA_PAYMENT_STARTED';
export const KLARNA_PAYMENT_VISIBLE = 'KLARNA_PAYMENT_VISIBLE';
export const KLARNA_SAVE_CARD = 'KLARNA_SAVE_CARD';
export const KLARNA_SCRIPT_LOADING = 'KLARNA_SCRIPT_LOADING';
export const KLARNA_SCRIPT_LOADED = 'KLARNA_SCRIPT_LOADED';
export const KLARNA_SCRIPT_FAILED = 'KLARNA_SCRIPT_FAILED';
export const KLARNA_SDK_SCRIPT_LOADING = 'KLARNA_SDK_SCRIPT_LOADING';
export const KLARNA_SDK_SCRIPT_LOADED = 'KLARNA_SDK_SCRIPT_LOADED';
export const KLARNA_SDK_SCRIPT_FAILED = 'KLARNA_SDK_SCRIPT_FAILED';
export const KLARNA_STUBS_MODAL_OPEN = 'KLARNA_STUBS_MODAL_OPEN';
export const KLARNA_STUBS_MODAL_CLOSE = 'KLARNA_STUBS_MODAL_CLOSE';

export const GTM_SCRIPT_APPENDED = 'GTM_SCRIPT_APPENDED';
export const GOOGLE_PAY_WALLET_ELIGIBLE = 'GOOGLE_PAY_WALLET_ELIGIBLE';
export const APPLE_PAY_BILLING_ADDRESS_MISSING = 'APPLE_PAY_BILLING_ADDRESS_MISSING';
export const TRANSACTION_STATUS_UPDATE = 'TRANSACTION_STATUS_UPDATE';
export const BIN_NUMBER_UPDATE = 'BIN_NUMBER_UPDATE';
export const MOUNT_POS_CREDIT_ADDRESS_FORM = 'MOUNT_POS_CREDIT_ADDRESS_FORM';
export const SHOW_POS_CREDIT_GET_READY_MODAL = 'SHOW_POS_CREDIT_GET_READY_MODAL';
export const CLOSE_POS_CREDIT_GET_READY_MODAL = 'CLOSE_POS_CREDIT_GET_READY_MODAL';
export const SET_CHECK_ELIGIBILITY_PROCESSING = 'SET_CHECK_ELIGIBILITY_PROCESSING';
export const SHOW_POS_CREDIT_BASKET_AMEND_MODAL = 'SHOW_POS_CREDIT_BASKET_AMEND_MODAL';
export const HIDE_POS_CREDIT_BASKET_AMEND_MODAL = 'HIDE_POS_CREDIT_BASKET_AMEND_MODAL';
export const SHOW_POS_CREDIT_SESSION_EXPIRED_ERROR = 'SHOW_POS_CREDIT_SESSION_EXPIRED_ERROR';
export const SHOW_POS_CREDIT_PROVIDER_UNAVAILABLE_ERROR = 'SHOW_POS_CREDIT_PROVIDER_UNAVAILABLE_ERROR';
export const SET_SELECTED_SAVED_CARD = 'SET_SELECTED_SAVED_CARD';
export const DETERMINE_SELECTED_PAYMENT_TYPE = 'DETERMINE_SELECTED_PAYMENT_TYPE';
export const PRINT_ORDER_BUTTON_CLICKED = 'PRINT_ORDER_BUTTON_CLICKED';
//
export const SET_CAN_MAKE_APPLE_PAY_PAYMENTS = 'SET_CAN_MAKE_APPLE_PAY_PAYMENTS';
export const SET_APPLE_PAY_PAYMENT_REQUEST = 'SET_APPLE_PAY_PAYMENT_REQUEST';
export const APPLE_PAY_PAYMENT = 'APPLE_PAY_PAYMENT';
export const APPLE_PAY_SET_CARD_TYPE = 'APPLE_PAY_SET_CARD_TYPE';
export const APPLE_PAY_REQUEST_PAYMENT_SESSION = 'APPLE_PAY_REQUEST_PAYMENT_SESSION';
export const APPLE_PAY_SUBMIT_PAYMENT_DATA = 'APPLE_PAY_SUBMIT_PAYMENT_DATA';
export const APPLE_PAY_PAYMENT_CANCEL = 'APPLE_PAY_PAYMENT_CANCEL';
export const APPLE_PAY_PAYMENT_FAILURE = 'APPLE_PAY_PAYMENT_FAILURE';
//
export const GOOGLE_PAY_STUBS_ENABLED = 'GOOGLE_PAY_STUBS_ENABLED';
export const SET_CAN_MAKE_GOOGLE_PAY_PAYMENTS = 'SET_CAN_MAKE_GOOGLE_PAY_PAYMENTS';
export const SET_GOOGLE_PAY_PAYMENTS_CLIENT = 'SET_GOOGLE_PAY_PAYMENTS_CLIENT';
export const GOOGLE_PAY_PAYMENT_DATA_RECEIVED = 'GOOGLE_PAY_PAYMENT_DATA_RECEIVED';
export const GOOGLE_PAY_PAYMENT_DATA_ERROR = 'GOOGLE_PAY_PAYMENT_DATA_ERROR';
export const GOOGLE_PAY_CANCEL = 'GOOGLE_PAY_CANCEL';
export const GOOGLE_PAY_STUBS_MODAL_OPEN = 'GOOGLE_PAY_STUBS_MODAL_OPEN';
export const CREATE_GOOGLE_PAY_PAYMENT = 'CREATE_GOOGLE_PAY_PAYMENT';
export const GOOGLE_PAY_SUBMIT_PAYMENT_DATA = 'GOOGLE_PAY_SUBMIT_PAYMENT_DATA';
//
export const SET_PROMO_CODE_ERROR = 'SET_PROMO_CODE_ERROR';
//
export const OCP_GET_PAYMENT_ID = 'OCP_GET_PAYMENT_ID';
export const OCP_GET_CORRELATION_ID = 'OCP_GET_CORRELATION_ID';
export const OCP_GET_SUBMISSION_SCRIPT = 'OCP_GET_SUBMISSION_SCRIPT';
export const OCP_SUBMIT_3DS_METHOD = 'OCP_SUBMIT_3DS_METHOD';
export const OCP_SUBMIT_3DS_METHOD_ERROR = 'OCP_SUBMIT_3DS_METHOD_ERROR';
export const OCP_GET_3DS_INFO = 'OCP_GET_3DS_INFO';
export const SET_OCP_3DS_FAIL_ERROR = 'SET_OCP_3DS_FAIL_ERROR';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const POST_PAYMENTS = 'POST_PAYMENTS';
export const TOGGLE_PRICE_PROMISE_MODAL = 'TOGGLE_PRICE_PROMISE_MODAL';

// CREATE ACCOUNT
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const SET_MYJL_MODAL_OPEN = 'SET_MYJL_MODAL_OPEN';
export const SET_MYJL_MODAL_CONTENT_INDEX = 'SET_MYJL_MODAL_CONTENT_INDEX';
export const JOIN_MY_JOHN_LEWIS = 'JOIN_MY_JOHN_LEWIS';
export const USE_FULL_SCREEN_SIGNUP_FLOW = 'USE_FULL_SCREEN_SIGNUP_FLOW';

// MARKETING PREFERENCES
export const UPDATE_MARKETING_PREFERENCES_V3 = 'UPDATE_MARKETING_PREFERENCES_V3';

// PAYMENT WALLET
export const GET_PAYMENT_WALLET = 'PAYMENT_WALLET';
export const PAYMENT_CARD_DELETE = 'PAYMENT_CARD_DELETE';
export const ANALYTICS_PAYMENT_CARD_DELETE_OPEN = '@@analytics/ANALYTICS_PAYMENT_CARD_DELETE_OPEN';

// FEATURES
export const GET_FEATURES = 'GET_FEATURES';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';

// AGE CHECK
export const USER_AGE_CHECK = 'USER_AGE_CHECK';
export const UPDATE_CUSTOMER_DOB = 'UPDATE_CUSTOMER_DOB';

// PAGE NOT FOUND
export const SET_PAGE_NOT_FOUND = 'SET_PAGE_NOT_FOUND';

// MOBILE APPS
export const APPS_REGISTER_CALLBACKS = 'APPS_REGISTER_CALLBACKS';
export const APPS_ACCESS_TOKEN_PROVIDED = 'APPS_ACCESS_TOKEN_PROVIDED';
export const APPS_CLEANUP_BASKET = 'APPS_CLEANUP_BASKET';
export const REFRESHING_APPS_ACCESS_TOKEN = 'REFRESHING_APPS_ACCESS_TOKEN';

// SCROLL TO  { DELIVERY DETAILS || PARTNERSHIP DISCOUNT TOGGLE }
export const SET_SCROLL_TO_DELIVERY_DETAILS = 'SET_SCROLL_TO_DELIVERY_DETAILS';
export const SET_SCROLL_TO_PARTNERSHIP_DISCOUNT_TOGGLE = 'SET_SCROLL_TO_PARTNERSHIP_DISCOUNT_TOGGLE';

// @@redux
export const REDUX_HYDRATE = 'persist/REHYDRATE';
export const REDUX_FORM_SUBMIT = '@@redux-form/SUBMIT';
export const REDUX_FORM_STOP_SUBMIT = '@@redux-form/STOP_SUBMIT';
export const REDUX_FORM_SET_SUBMIT_FAILED = '@@redux-form/SET_SUBMIT_FAILED';

// @@router
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

// PREFERENCES
export const SAVE_PREFERENCES_COLLECTION = 'SAVE_PREFERENCES_COLLECTION';
export const SAVE_PREFERENCES_DELIVERY = 'SAVE_PREFERENCES_DELIVERY';
export const SAVE_PREFERENCES_PAYMENT_CARD = 'SAVE_PREFERENCES_PAYMENT_CARD';
export const SAVE_PREFERENCES_PAYMENT_METHOD = 'SAVE_PREFERENCES_PAYMENT_METHOD';
export const DELETE_PREFERENCES = 'DELETE_PREFERENCES';

// @@dev
export const SET_LOG_ANALYTICS = '@@dev/SET_LOG_ANALYTICS';

// AUTH0
export const AUTH0_CALLBACK = 'AUTH0_CALLBACK';
export const AUTH0_UNAVAILABLE = 'AUTH0_UNAVAILABLE';
export const AUTH0_HEALTH_CHECK = 'AUTH0_HEALTH_CHECK';
export const AUTH0_CLAIM_ORDER_CALLBACK = 'AUTH0_CLAIM_ORDER_CALLBACK';
export const AUTH0_REGISTRATION_CALLBACK = 'AUTH0_REGISTRATION_CALLBACK';
export const SHOW_EMAIL_VALIDATION_ERROR = 'SHOW_EMAIL_VALIDATION_ERROR';
export const ALLOW_INIT_DELIVERY_PAGE_CALLS = 'ALLOW_INIT_DELIVERY_PAGE_CALLS';

// age verification
export const GET_AGE_VERIFICATION_SESSION = 'GET_AGE_VERIFICATION_SESSION';
export const GET_AGE_VERIFICATION_RESULT = 'GET_AGE_VERIFICATION_RESULT';
export const SET_AGE_VERIFICATION_LOADING_STATE = 'SET_AGE_VERIFICATION_LOADING_STATE';
export const SET_AGE_VERIFICATION_CONFIRMATION_STATE = 'SET_AGE_VERIFICATION_CONFIRMATION_STATE';
export const AGE_VERIFICATION_SKIP_RESTRICTED = 'AGE_VERIFICATION_SKIP_RESTRICTED';
export const AGE_VERIFICATION_VERIFY_AGE_CLICK = 'AGE_VERIFICATION_VERIFY_AGE_CLICK';
export const AGE_VERIFICATION_FAILED_RETURN_TO_BASKET_CLICK = 'AGE_VERIFICATION_FAILED_RETURN_TO_BASKET_CLICK';
export const AGE_VERIFICATION_FAILED = 'AGE_VERIFICATION_FAILED';
export const AGE_VERIFICATION_SUCCESS = 'AGE_VERIFICATION_SUCCESS';
export const AGE_VERIFICATION_BLADED_ARTICLE = 'AGE_VERIFICATION_BLADED_ARTICLE';

// REWARDS
export const APPLY_REWARDS = 'APPLY_REWARDS';
export const REMOVE_REWARDS = 'REMOVE_REWARDS';
export const ANALYTICS_REWARDS_TOOLTIP = 'ANALYTICS_REWARDS_TOOLTIP';
export const ANALYTICS_REWARDS_TOGGLE = 'ANALYTICS_REWARDS_TOGGLE';

// IMPRESSIONS
export const RECORD_IMPRESSIONS = 'RECORD_IMPRESSIONS';

// TIMINGs
export const SAVE_TIME_TO_AUTH0TOKEN = 'SAVE_TIME_TO_AUTH0TOKEN';
export const SAVE_TIME_TO_DELIVERY_PAGE = 'SAVE_TIME_TO_DELIVERY_PAGE';
export const SAVE_TIME_TO_DELIVERY_METHODS = 'SAVE_TIME_TO_DELIVERY_METHODS';
export const SAVE_TIME_TO_COLLECTION_DATES = 'SAVE_TIME_TO_COLLECTION_DATES';
export const DELIVERY_TIMINGS_RECORDED = 'DELIVERY_TIMINGS_RECORDED';
export const SAVE_TIME_CONTINUE_TO_PAYMENT_CLICKED = 'SAVE_TIME_CONTINUE_TO_PAYMENT_CLICKED';
export const PAYMENT_TIMINGS_RECORDED = 'PAYMENT_TIMINGS_RECORDED';
export const CONFIRMATION_TIMINGS_RECORDED = 'CONFIRMATION_TIMINGS_RECORDED';
export const SAVE_TIME_PLACE_ORDER_CLICKED = 'SAVE_TIME_PLACE_ORDER_CLICKED';

// Component suspense failure
export const LAZY_COMPONENT_WITH_SUSPENSE = 'LAZY_COMPONENT_WITH_SUSPENSE';

// EXPRESS
export const EXPRESS_PUT_DELIVERY_ADDRESS = 'EXPRESS_PUT_DELIVERY_ADDRESS';
export const EXPRESS_GET_DELIVERY_METHODS = 'EXPRESS_GET_DELIVERY_METHODS';
export const EXPRESS_PUT_DELIVERY_DETAILS = 'EXPRESS_PUT_DELIVERY_DETAILS';
export const SHOW_EXPRESS_PAYMENTS = 'SHOW_EXPRESS_PAYMENTS';
export const EXPRESS_PAYMENTS_FAILED = 'EXPRESS_PAYMENTS_FAILED';
export const INIT_EXPRESS_PAYMENT = 'INIT_EXPRESS_PAYMENT';
export const EXPRESS_PAYMENTS_VALIDATION_FAILED = 'EXPRESS_PAYMENTS_VALIDATION_FAILED';

// RavelinJS
export const RAVELIN_INSTANCE_LOADED = 'RAVELIN_INSTANCE_LOADED';
