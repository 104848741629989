export default {
  BASKET_PAGE_URL: 'http://www.johnlewis.com/basket',
  GOOGLEMAPS_API_URL: 'https://maps.googleapis.com/maps/api/js?key=',
  HOME_PAGE_URL: 'http://www.johnlewis.com',
  JL_BLADED_ITEMS_ISSUES: 'https://www.johnlewis.com/customer-services/shopping-with-us/buying-bladed-items-issues',
  JL_PRIVACY_NOTICE: 'https://www.johnlewis.com/customer-services/shopping-with-us/privacy-notice',
  JL_TERMS_CONDITIONS: 'https://www.johnlewis.com/customer-services/shopping-with-us/terms-and-conditions',
  KLARNA_PRIVACY_NOTICE: 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/privacy',
  KLARNA_TERMS_CONDITIONS: 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/user',
};
