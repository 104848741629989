import { push, replace } from 'connected-react-router';
import { QUANTITY_INPUT } from 'jl-design-system/form/fields';
import { setFieldValue } from 'jl-design-system/redux/actions/form/formActions';
// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  ANALYTICS_EDIT_BASKET_MODAL_OPEN,
  SET_EDIT_BASKET_BASE_ROUTE,
  REBATCH_ORDER,
  PUT_ITEM_QUANTITY,
  PUT_ITEMS_QUANTITIES,
  ANALYTICS_AMEND_BASKET_MODAL_OPEN,
  SHOW_ITEM_REMOVE_OVERLAY,
  HANDLE_EDIT_BASKET_QUANTITY_ERROR,
  HIDE_ITEM_REMOVE_OVERLAY,
  REMOVE_BASKET_ITEMS,
  GET_ITEMS,
  GET_ITEMS_NO_ACTION,
  BASKET_ITEM_OUT_OF_STOCK,
  SHOW_BATCHING_FAILURE_MODAL,
  ANALYTICS_BATCH_ERROR,
  ANALYTICS_BATCH_ERROR_ITEMS_REMOVED,
  SHOW_REMOVE_BATCHING_FAILURE_ERROR,
  ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED,
  ANALYTICS_SHOW_COLLECTION_HELP_REMOVE,
  CLOSE_EDIT_BASKET_MODAL,
} from '../../../constants/actionConstants';
import {
  URL_PUT_ITEM_QUANTITY,
  URL_PUT_ITEMS_QUANTITIES,
  URL_GET_ITEMS,
  URL_REBATCH_ORDER,
  URL_REMOVE_ITEMS_FROM_BASKET,
  URL_REMOVE_TRADE_IN_ITEM,
  URL_SAVE_FOR_LATER,
} from '../../../constants/endpointConstants';
import routeConstants from '../../../constants/routeConstants';
import deliveryConstants from '../../../constants/deliveryConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { triggerAnalyticsEvent } from '../analytics/analyticsAction';
import { showDisableSiteSpinner, showEmptyBasketNotification } from '../app/appActions';
import { hidePOSCreditBasketAmendModal, postPayments } from '../payment/paymentActions';
import { applyRewards } from '../payment/rewardsActions';
import {
  findCollectionPoint,
  selectCollectionPoint,
  getSavedCollectionPoints,
} from '../click-and-collect/clickAndCollectActions';
import { isApps } from '../../reducers/app/appReducer';
import {
  initDeliveryPage,
  refreshDeliveryMethods,
  setDeliveryAddressAndGetDeliveryMethods,
} from '../delivery/deliveryActions';

import getErrorMessageObject from '../../../utils/error/getErrorMessageObject';
import { getFormId } from '../../../utils/form/configs/itemQuantity';
import isItemFabric, { itemIsM2M } from '../../../utils/orderform/isItemFabric';
import { handbackTo } from '../data-action-link/dataActionLinkActions';
import { shouldShowCollectionHelpText } from '../../../utils/collection/collectionHelp';
import { isPaymentPage } from '../../../utils/helpers/pageType';
import { shouldTriggerApplyRewards } from '../../../utils/payment/shouldApplyRewards';
import { shouldRemoveAndSaveForLater } from '../../../utils/saveForLater/saveForLaterHelp';

export const showEditBasketModal = baseRoutePath => (dispatch) => {
  dispatch({
    type: SET_EDIT_BASKET_BASE_ROUTE,
    baseRoutePath,
  });

  dispatch(push(routeConstants.EDIT_BASKET));
  dispatch(triggerAnalyticsEvent(ANALYTICS_EDIT_BASKET_MODAL_OPEN));
};

export const rebatchOrder = address => ({
  type: REBATCH_ORDER,
  request: client => client({
    path: URL_REBATCH_ORDER,
    config: { method: 'POST' },
  }),
  address,
  orderHasBeenRebatched: true,
});

export const rebatchOrderAfterBasketEdit = ({
  shouldDispatchApplyRewards = true,
  shouldInitDeliveryPage = false,
} = {}) => async (dispatch, getState) => {
  const orderNeedsToBeRebatched = get(getState(), 'orderForm.orderNeedsToBeRebatched');
  const address = get(getState(), 'delivery.confirmedDeliveryAddress');
  const selectedDeliveryChoiceId = get(getState(), 'delivery.selectedDeliveryChoiceId');
  const pathname = get(getState().router, 'location.pathname', '');
  const editFromPaymentPage = isPaymentPage({ pathname });

  if (orderNeedsToBeRebatched) {
    dispatch(showDisableSiteSpinner());

    const response = await dispatch(rebatchOrder(address));

    if (response.type === `${REBATCH_ORDER}.SUCCESS`) {
      const hasConfirmedDeliveryAddress = !!get(getState(), 'delivery.confirmedDeliveryAddress');
      const selectedDeliveryAddress = get(getState(), 'user.selectedDeliveryAddress');

      if (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT) {
        dispatch(replace(routeConstants.CLICK_AND_COLLECT));
        const searchTerm = get(getState(), 'clickAndCollect.collectionPointSearchTerm');
        const selectedCollectionPoint = get(getState(), 'clickAndCollect.selectedCollectionPoint');
        const savedCollectionPoints = get(getState(), 'user.collectionPoints');


        if (!isEmpty(savedCollectionPoints)) {
          // refresh saved collection points as collection charges may have changed
          await dispatch(getSavedCollectionPoints());
        }

        if (searchTerm) {
          // refresh collection point search results as collection charges may have changed
          await dispatch(findCollectionPoint(searchTerm, true));
        }

        if (selectedCollectionPoint) {
          // re-select selected collection point to get latest dates & collection charges
          await dispatch(selectCollectionPoint({
            collectionPoint: selectedCollectionPoint,
            refresh: true,
          }));
        }

      } else if (hasConfirmedDeliveryAddress) {
        if (editFromPaymentPage) {
          await dispatch(replace(routeConstants.DELIVERY_OPTIONS));
        } else if (!shouldInitDeliveryPage) {
          await dispatch(refreshDeliveryMethods());
        }
      } else if (selectedDeliveryAddress) {
        await dispatch(setDeliveryAddressAndGetDeliveryMethods({
          address: selectedDeliveryAddress,
        }));
      }

      if (!editFromPaymentPage) {
        if (!shouldInitDeliveryPage) {
          const {
            orderForm: {
              submitOrderFailed,
            } = {},
            payment: {
              isExpressPayment,
            } = {},
          } = getState();

          if (submitOrderFailed && isExpressPayment) await dispatch(postPayments());
        } else {
          await dispatch(initDeliveryPage());
        }

        if (shouldDispatchApplyRewards) {
          const canApplyRewards = shouldTriggerApplyRewards(getState());

          if (canApplyRewards) {
            dispatch(applyRewards());
          }
        }
      }
    }
  }
};

export const showItemRemoveOverlay = itemId => ({
  type: SHOW_ITEM_REMOVE_OVERLAY,
  itemId,
});

export const hideItemRemoveOverlay = itemId => ({
  type: HIDE_ITEM_REMOVE_OVERLAY,
  itemId,
});

export const getItems = ({ isAgeVerification = false, noAction = false } = {}) => ({
  type: noAction ? GET_ITEMS_NO_ACTION : GET_ITEMS,
  isAgeVerification,
  request: client => client({
    path: URL_GET_ITEMS,
    config: { method: 'GET' },
  }),
});

export const saveItems = itemIds => ({
  type: REMOVE_BASKET_ITEMS,
  itemIds: itemIds.ids,
  products: itemIds?.ids.map(id => ({ productId: id })),
  request: client => client({ path: URL_SAVE_FOR_LATER, config: { method: 'POST', body: itemIds } }),
});

export const removeItems = ({
  itemIds,
  isTradeIn = false,
  isChildItem = false,
  forceRemove = false,
  rebatchNotNeeded = false,
}) => (dispatch, getState) => {
  const isSignedIn = get(getState().user, 'isSignedIn', false);
  const isM2M = getState().orderForm?.items?.some(item => itemIds.includes(item?.id) && itemIsM2M(item.type));
  const shouldSaveForLater = shouldRemoveAndSaveForLater({
    isSignedIn,
    isChildItem,
    isTradeIn,
    isM2M,
  });

  const itemIdsString = itemIds.toString();
  let path = URL_REMOVE_ITEMS_FROM_BASKET(itemIdsString);

  if (isTradeIn) {
    path = URL_REMOVE_TRADE_IN_ITEM(itemIds);
  }

  if (!shouldSaveForLater || forceRemove) {
    return dispatch({
      type: REMOVE_BASKET_ITEMS,
      request: client => client({ path, config: { method: 'DELETE' } }),
      itemIds,
      products: itemIds?.map(id => ({ productId: id })),
      isTradeIn,
      ...rebatchNotNeeded && { rebatchNotNeeded },
    });
  }

  return dispatch(saveItems({ ids: itemIds }));
};

export const handleRemoveItem = ({
  itemId,
  lastItemInBasket,
  isChildItem,
  isTradeIn,
  isAgeVerification,
}) => async (dispatch, getState) => {
  const canApplyRewards = shouldTriggerApplyRewards(getState());
  const stateBeforeRemove = getState();

  const response = await dispatch(removeItems({ itemIds: [itemId], isTradeIn, isChildItem }));

  if (response.type === `${REMOVE_BASKET_ITEMS}.SUCCESS`) {
    if (shouldShowCollectionHelpText({
      id: itemId,
      isChildItem,
      state: stateBeforeRemove,
    })) {
      dispatch(triggerAnalyticsEvent(ANALYTICS_SHOW_COLLECTION_HELP_REMOVE));
    }

    const state = getState();
    if (lastItemInBasket) {
      const browseUrl = get(state, 'bff.browseUrl');
      dispatch(handbackTo(browseUrl));

      if (isApps(state)) {
        dispatch(showEmptyBasketNotification());
      }
    } else {
      await dispatch(getItems({ isAgeVerification }));

      if (canApplyRewards) {
        dispatch(applyRewards({ isCustomerAction: true }));
      }
    }
  }
};

export const updateItemQuantity = ({
  itemId,
  quantity,
}) => async (dispatch, getState) => {
  const canApplyRewards = shouldTriggerApplyRewards(getState());
  const showEditBasketModal = getState()?.delivery?.showEditBasketModal;

  if (showEditBasketModal) {
    const response = await dispatch({
      type: PUT_ITEM_QUANTITY,
      request: client => client({
        path: URL_PUT_ITEM_QUANTITY(itemId),
        config: {
          method: 'PUT', body: { quantity },
        },
      }),
      itemId,
    });

    if (canApplyRewards && response.type === `${PUT_ITEM_QUANTITY}.SUCCESS`) {
      dispatch(applyRewards({ isCustomerAction: true }));
    }

    if (response.type === `${PUT_ITEM_QUANTITY}.FAILED`) {

      if (response.error) {

        const errorCode = get(response.error, 'code');

        if (errorCode === errorCodeConstants.ITEM_NOT_IN_STOCK) {
          dispatch({
            type: BASKET_ITEM_OUT_OF_STOCK,
            itemId,
          });
        }

        const getItemsResponse = await dispatch(getItems());

        if (
          errorCode === errorCodeConstants.ITEM_QUANTITY_PURCHASE_LIMIT ||
          errorCode === errorCodeConstants.QUANTITY_REDUCED_TO_AVAILABLE_STOCK ||
          errorCode === errorCodeConstants.ALL_AVAILABLE_STOCK_ALREADY_ADDED ||
          errorCode === errorCodeConstants.ITEM_QUANTITY_INVALID
        ) {
          const items = get(getItemsResponse.result, 'orderForm.items');
          const item = items.find(item => item.id === itemId);
          const quantity = item?.quantity;
          const formId = getFormId(itemId);

          // ensure field value is updated with correct quantity
          if (quantity) {
            await dispatch(setFieldValue(formId, [QUANTITY_INPUT.id], quantity.toString()));
          }

          const error = getErrorMessageObject({
            error: response.error,
            vars: {
              isItemFabric: isItemFabric(item),
              stockLevel: get(item, 'availability.stockLevel'),
              quantityLimit: get(item, 'availability.quantityLimit'),
            },
          });
          dispatch({
            type: HANDLE_EDIT_BASKET_QUANTITY_ERROR,
            errorMessage: error.message,
            itemId,
          });
        }
      }
    }
  }
};

export const batchUpdateItemQuantity = itemsQuantity => ({
  type: PUT_ITEMS_QUANTITIES,
  request: client => client({
    path: URL_PUT_ITEMS_QUANTITIES,
    config: {
      method: 'PUT',
      body: {
        itemsQuantity,
      },
    },
  }),
});

export const showLimitedStockModal = baseRoutePath => (dispatch) => {
  dispatch({
    type: SET_EDIT_BASKET_BASE_ROUTE,
    baseRoutePath,
  });
  dispatch(triggerAnalyticsEvent(ANALYTICS_AMEND_BASKET_MODAL_OPEN));
};

export const showBatchingFailureModal = batchingFailureIds => async (dispatch, getState) => {
  const getItemsResponse = await dispatch(getItems());
  if (getItemsResponse.type === `${GET_ITEMS}.SUCCESS`) {
    const items = get(getItemsResponse, 'result.orderForm.items', []);
    const batchingFailureItems = items?.filter(item => batchingFailureIds.includes(item.id));
    const allItemsHaveBatchingFailure = items.length === batchingFailureIds.length;

    dispatch({
      type: SHOW_BATCHING_FAILURE_MODAL,
      batchingFailureItems,
      allItemsHaveBatchingFailure,
    });

    const analyticsProducts = get(getState().analytics, 'analyticsData.checkout.product');

    dispatch(triggerAnalyticsEvent(ANALYTICS_BATCH_ERROR, {
      products: batchingFailureItems.map(
        item => analyticsProducts?.find(product => product.productId === item.productId),
      ),
    }));
  }
};

export const removeBatchingFailureItems = items => async (dispatch, getState) => {
  const itemIds = items.map(item => (item.id));
  const removeItemsResponse = await dispatch(removeItems({ itemIds, forceRemove: true, rebatchNotNeeded: true }));

  if (removeItemsResponse.type === `${REMOVE_BASKET_ITEMS}.SUCCESS`) {
    const analyticsProducts = get(getState().analytics, 'analyticsData.checkout.product');
    const removedProducts = items.map(item => analyticsProducts?.find(product => product.productId === item.productId));

    dispatch(triggerAnalyticsEvent(ANALYTICS_BATCH_ERROR_ITEMS_REMOVED, { products: removedProducts }));
    dispatch(initDeliveryPage({ isDeliveryPageInitialised: true }));
  } else {
    dispatch({ type: SHOW_REMOVE_BATCHING_FAILURE_ERROR });
  }
};

export const removeItemsFromBasket = ({
  itemIds,
  shouldInitDeliveryPage = false,
  forceRemove = false,
} = {}) => async (dispatch) => {
  await dispatch(removeItems({ itemIds, forceRemove }));
  await dispatch(rebatchOrderAfterBasketEdit({ shouldInitDeliveryPage }));
  dispatch(getItems());
};

export const removePOSCreditIneligibleItems = () => async (dispatch, getState) => {
  const itemIds = get(getState(), 'orderForm.posCreditIneligibleItemIds', []);
  const response = await dispatch(removeItems({ itemIds }));
  const { type } = response;

  const address = get(getState().delivery, 'confirmedDeliveryAddress');
  await dispatch(rebatchOrder(address));

  if (type === `${REMOVE_BASKET_ITEMS}.SUCCESS`) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED, {
      products: itemIds.map(productId => ({
        productId,
      })),
    }));
    dispatch(getItems());
    dispatch(hidePOSCreditBasketAmendModal());
    dispatch(push(routeConstants.DELIVERY));
  }
};

export const closeEditBasketModal = () => async (dispatch) => {
  dispatch({ type: CLOSE_EDIT_BASKET_MODAL });
};
