import { push, goBack } from 'connected-react-router';
// jl-design-system
import { resetForm } from 'jl-design-system/redux/actions/form/formActions';
import stripWhitespace from 'jl-design-system/utils/string/stripWhitespace';
import transformUkPhoneNumber from 'jl-design-system/form/normalizers/transformUkPhoneNumber';

// lodash
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
//
import {
  ANALYTICS_COLLECTION_POINT_DOWN,
  ANALYTICS_COLLECTION_POINT_CUT_OFF_MODAL_SHOWN,
  ANALYTICS_COLLECTION_POINT_SUCCESS,
  ANALYTICS_FIND_COLLECTION_POINT_FAILED,
  SET_COLLECTION_POINT_VIEW_TYPE,
  CLEAR_COLLECTION_POINT_SELECTION,
  FIND_COLLECTION_POINT,
  GET_COLLECTION_POINT_DETAILS,
  SELECT_COLLECTION_POINT,
  SET_FIND_COLLECTION_POINT_ERROR,
  NEW_COLLECTION_POINT_SEARCH,
  PUT_CC_DELIVERY_DETAILS,
  PUT_CC_DELIVERY_DETAILS_SILENTLY,
  SET_ACTIVE_MAP_MARKER,
  SET_ACTIVE_MODAL_MAP_MARKER,
  GET_COLLECTION_POINT_DATES,
  SET_CLICK_COLLECT_COUNTDOWN_VISIBILITY,
  SET_CLICK_COLLECT_CUT_OFF_PASSED,
  SET_CLICK_COLLECT_CUT_OFF_PASSED_MODAL_VISIBILITY,
  DELETE_COLLECTION_POINT,
  SHOW_REMOVE_COLLECTION_POINT_OVERLAY,
  HIDE_REMOVE_COLLECTION_POINT_OVERLAY,
  GET_SAVED_COLLECTION_POINTS,
  SET_GEO_LOCATION_SEARCH_ACTIVE_STATUS,
  SILENTLY_SELECT_COLLECTION_DATE,
  GOOGLE_MAPS_SCRIPT_LOADED,
  GOOGLE_MAPS_SCRIPT_LOADING,
  CHANGE_COLLECTION_POINT,
} from '../../../constants/actionConstants';
import {
  VIEW_TYPE_MAP,
  STORE_DETAILS,
  SEARCH_TERMS_TO_MODIFY,
} from '../../../constants/clickCollectConstants';
import featureConstants from '../../../constants/featureConstants';
import {
  getUnavailableStores,
  getUnavailableStoresAnalyticsInfo,
  selectClickAndCollectStoreAnalytics,
} from '../../../utils/analytics/utils/getCollectionAnalytics';
import { isCollectPlusRelatedType } from '../../../utils/collection/getCollectPlusRelatedStores';
import {
  URL_COLLECTION_POINTS,
  URL_COLLECTION_POINT_BY_ID,
  URL_COLLECTION_DETAILS,
  URL_CUSTOMER_COLLECTION_POINTS,
  URL_COLLECTION_DATES,
  URL_DELETE_COLLECTION_POINT,
} from '../../../constants/endpointConstants';
import triggerAnalyticsEvent from '../analytics/analyticsAction';
import { initPaymentPage } from '../payment/orderFormActions';
import { recordCollectionDateTimings, saveTimeContinueToPaymentClicked } from '../timing/timingActions';
import { initDeliveryPage } from '../delivery/deliveryActions';
import routeConstants from '../../../constants/routeConstants';
import getGiftMessageLines from '../../../utils/delivery/getGiftMessageLines';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import handleInconsistentOrderFormState from '../../../utils/orderform/handleInconsistentOrderFormState';
import daysBetweenDateAndStartOfWeek from '../../../utils/helpers/date/daysBetweenDateAndStartOfWeek';
import sortCollectionPointsByDistance from '../../../utils/collection/sortCollectionPointsByDistance';
import { COLLECTOR_DETAILS_FORM_ID } from '../../../utils/form/configs/collectorDetails';
import isCollectionPointAvailable from '../../../utils/collection/isCollectionPointAvailable';
import { recordImpressions } from '../app/appActions';

export function getClickCollectCollectionPoints(unfilteredCollectionPoints = []) {
  const filteredCollectionPoints = unfilteredCollectionPoints.filter(
    point => !isCollectPlusRelatedType(point.ownerId),
  );

  return sortCollectionPointsByDistance(filteredCollectionPoints);
}

export function getCollectPlusRelatedCollectionPoints(unfilteredCollectionPoints = []) {
  const filteredCollectionPoints = unfilteredCollectionPoints.filter(
    point => isCollectPlusRelatedType(point.ownerId),
  );

  return sortCollectionPointsByDistance(filteredCollectionPoints);
}

export const determinePreSelectedCollectionPoint = (userCollectionPoints) => {
  if (userCollectionPoints.length > 0) {
    const userSelectedDefaultCollectionPoint = userCollectionPoints.find(point => point.isDefault || point.default);

    if (userSelectedDefaultCollectionPoint) {
      return userSelectedDefaultCollectionPoint;
    }

    if (userCollectionPoints[0]) {
      return userCollectionPoints[0];
    }
  }

  return undefined;
};

export const submitCCDeliveryDetails = body => (dispatch, getState) => {

  // analytics details
  let detailsToKeep = {
    clickandCollectType: 'standard',
  };

  const collectionPoint = get(getState(), 'clickAndCollect.selectedCollectionPoint', {});
  const selectedCollectionTime = get(getState(), 'clickAndCollect.selectedCollectionTime');
  const collectionPointDates = get(getState(), 'clickAndCollect.collectionPointDates');

  if (selectedCollectionTime && collectionPointDates) {
    const firstDateInCollectionPointDates = get(collectionPointDates, '[0].date');
    const firstAvailableDay = daysBetweenDateAndStartOfWeek(firstDateInCollectionPointDates);
    const selectedDaysUntilDelivery = daysBetweenDateAndStartOfWeek(selectedCollectionTime);

    detailsToKeep = {
      firstAvailableDay,
      clickandCollectType: 'named day',
      selectedDaysUntilDelivery,
      deliveryMessage: 'Click and Collect',
    };
  }

  return dispatch({
    type: PUT_CC_DELIVERY_DETAILS,
    request: client => client({ path: URL_COLLECTION_DETAILS, config: { method: 'PUT', body } }),
    collectionPoint,
    body,
    ...detailsToKeep,
  });
};

export const getCollectionPointDates = body => ({
  type: GET_COLLECTION_POINT_DATES,
  request: client => client({ path: URL_COLLECTION_DATES, config: { method: 'POST', body } }),
  selectingCollectionPointId: body.collectionPointId,
});

export const nextDayCollectionDateExists = (collectionPointDates, selectedCollectionDate) => {
  const selectedDateIndex = collectionPointDates.findIndex(
    date => date.fulfilmentOfferId === selectedCollectionDate.fulfilmentOfferId,
  );
  const nextDateExists = !!collectionPointDates[selectedDateIndex + 1];

  return nextDateExists;
};

export const silentlySelectCollectionDate = payload => ({
  type: SILENTLY_SELECT_COLLECTION_DATE,
  payload,
});

export const getAndSelectNewCollectionDate = () => async (dispatch, getState) => {
  const datesBody = {
    batchId: get(getState(), 'delivery.deliveries[0].id'),
    collectionPointId: get(getState(), 'clickAndCollect.selectedCollectionPoint.id'),
  };

  const dateResponse = await dispatch(getCollectionPointDates(datesBody));
  const date = get(dateResponse, 'result.collectionDates[0].date');

  if (date) return dispatch(silentlySelectCollectionDate(date));

  return {};
};

export const setCutOffPassedModalVisibility = visible => async (dispatch, getState) => {
  const selectedCollectionDate = get(getState(), 'clickAndCollect.selectedCollectionDate', {});
  const collectionPointDates = get(getState(), 'clickAndCollect.collectionPointDates', []);

  if (visible) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_COLLECTION_POINT_CUT_OFF_MODAL_SHOWN));

    // check that we have another date to suggest and get new date if not
    if (!nextDayCollectionDateExists(collectionPointDates, selectedCollectionDate)) {
      await dispatch(getAndSelectNewCollectionDate());
    }
  }

  dispatch({
    type: SET_CLICK_COLLECT_CUT_OFF_PASSED_MODAL_VISIBILITY,
    visible,
  });
};

export const submitClickAndCollectDeliveryDetails = ({
  formValues = {},
  shouldRecordDeliveryTimings = false,
}) => async (dispatch, getState) => (
  new Promise(async (resolve) => {
    const state = getState();
    const {
      countdownVisible = false,
      cutOffPassed = false,
      showCutOffPassedModal = false,
    } = state.clickAndCollect ?? {};

    if (cutOffPassed && countdownVisible && !showCutOffPassedModal) {
      dispatch(setCutOffPassedModalVisibility(true));
      resolve();
    } else {
      dispatch(saveTimeContinueToPaymentClicked());
      const {
        collectionPointDates,
        selectedCollectionDate = {},
        suggestedNewCollectionDate,
      } = state.clickAndCollect ?? {};

      const { ...rest } = formValues;
      const giftMessage = state.app?.defaultGiftMessageValue?.giftReceipt ?? '';
      const giftReceipt = state.app?.isGiftReceiptSelected?.giftReceipt ?? false;

      const collectorDetails = {
        ...rest,
        giftMessageLines: getGiftMessageLines({ giftMessage, giftReceipt }),
      };

      const fulfilmentOfferId = cutOffPassed ?
        suggestedNewCollectionDate?.fulfilmentOfferId :
        selectedCollectionDate?.fulfilmentOfferId || get(collectionPointDates, '[0].fulfilmentOfferId');

      const body = {
        collectorDetails: {
          primaryPhone: stripWhitespace(transformUkPhoneNumber(collectorDetails.phoneNumber || '')),
        },
        fulfilment: {
          fulfilmentOfferId,
          giftMessageLines: collectorDetails.giftMessageLines,
        },
      };

      const response = await dispatch(submitCCDeliveryDetails(body));

      if (response.type === `${PUT_CC_DELIVERY_DETAILS}.SUCCESS`) {
        dispatch(initPaymentPage({
          pushAfter: true,
          shouldRecordDeliveryTimings,
        }));
      } else if (response.error?.code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE) {
        await dispatch(handleInconsistentOrderFormState());
      } else {
        dispatch(initDeliveryPage());
        dispatch(push(routeConstants.DELIVERY));
      }

      resolve();
    }
  })
);

export const getCollectionPointById = (collectionPointSearchTerm, refresh = false) => async (dispatch) => {
  let searchTermForAPI = collectionPointSearchTerm;
  if (SEARCH_TERMS_TO_MODIFY.includes(collectionPointSearchTerm.toLowerCase())) {
    searchTermForAPI = encodeURI(`${collectionPointSearchTerm} UK`);
  }

  return dispatch({
    type: FIND_COLLECTION_POINT,
    collectionPointSearchTerm,
    request: client => client({
      path: URL_COLLECTION_POINTS(searchTermForAPI),
      config: { method: 'GET' },
    }),
    refresh,
  });
};

export const findCollectionPoint = (collectionPointSearchTerm, refresh = false) => async (dispatch, getState) => {
  const response = await dispatch(getCollectionPointById(collectionPointSearchTerm, refresh));

  if (response.type === `${FIND_COLLECTION_POINT}.FAILED`) {
    dispatch(triggerAnalyticsEvent(ANALYTICS_COLLECTION_POINT_DOWN));
    if (response.error.code !== errorCodeConstants.INCOMPLETE_COLLECTION_SEARCH) {
      dispatch(push(routeConstants.DELIVERY));
    }
  }

  if (response.type === `${FIND_COLLECTION_POINT}.SUCCESS`) {
    const collectionPointsFound = get(response.result, 'collectionPoints', []).length;
    if (!collectionPointsFound) {
      dispatch(triggerAnalyticsEvent(ANALYTICS_FIND_COLLECTION_POINT_FAILED));
      return;
    }

    const searchNewCollectPoint = getState().clickAndCollect.collectionPointSearchTerm;
    const collectionPointSearchResults = getState().clickAndCollect.collectionPointSearchResults;
    const clickCollectCollectionPoints = getClickCollectCollectionPoints(collectionPointSearchResults);
    const collectPlusCollectionPoints = getCollectPlusRelatedCollectionPoints(collectionPointSearchResults);
    const unavailableStores = getUnavailableStores(collectionPointSearchResults);

    const deliveryProposition = {
      method: 'col',
      selected: 1,
      deliveryId: '',
      message: '',
      searchTerm: searchNewCollectPoint,
      ccShopsShown: clickCollectCollectionPoints.length,
      cpShopsShown: collectPlusCollectionPoints.length,
    };

    deliveryProposition.countdownVisible = !!getState().clickAndCollect?.countdownVisible;

    dispatch(triggerAnalyticsEvent(ANALYTICS_COLLECTION_POINT_SUCCESS, {
      analytics: {
        checkout: {
          batch: [{
            deliveryProposition: [deliveryProposition],
          }],
        },
        error: unavailableStores.map(item => ({
          type: 'checkout:info',
          message: getUnavailableStoresAnalyticsInfo(item),
        })),
      },
    }));
  }
};

export const newCollectionPointSearch = () => ({
  type: NEW_COLLECTION_POINT_SEARCH,
});

// used when C&C store is selected and partner discount is toggled on/off
// used when edit basket could have caused a change in collection point charges
export const getCollectionPointDetails = collectionPointId => ({
  type: GET_COLLECTION_POINT_DETAILS,
  request: client => client({ path: URL_COLLECTION_POINT_BY_ID(collectionPointId), config: { method: 'GET' } }),
});

export const getSavedCollectionPoints = () => ({
  type: GET_SAVED_COLLECTION_POINTS,
  request: client => client({ path: URL_CUSTOMER_COLLECTION_POINTS, config: { method: 'GET' } }),
});

// TODO rewrite this function to be simpler
// https://www.jlpit.com/jira/browse/MARV-10645
export const selectCollectionPoint = ({
  collectionPoint: _collectionPoint,
  refresh = false,
  selectedIndex = 0,
  shouldRecordDeliveryTimings = false,
  datesAlreadyLoaded = false,
  condensed = false,
  savedCollectionView = false,
}) => async (dispatch, getState) => {

  let collectionPoint = { ..._collectionPoint };

  if (refresh) {
    const getDetails = await dispatch(getCollectionPointDetails(collectionPoint.id));

    if (getDetails.type === `${GET_COLLECTION_POINT_DETAILS}.SUCCESS`) {
      collectionPoint = getDetails.result.collectionPoints[0];
    }

    if (getDetails.type === `${GET_COLLECTION_POINT_DETAILS}.FAILED`) {
      dispatch(push(routeConstants.DELIVERY));
      return;
    }
  }

  const deliveryOptions = get(getState(), 'delivery.deliveryOptions');
  const isAvailable = isCollectionPointAvailable(collectionPoint, deliveryOptions);

  if (isAvailable && !datesAlreadyLoaded) {
    await dispatch(getCollectionPointDates({
      batchId: get(getState(), 'delivery.deliveries[0].id'),
      collectionPointId: collectionPoint.id,
    }));
  }

  const searchTerm = get(getState(), 'clickAndCollect.collectionPointSearchTerm');
  const collectionDates = get(getState(), 'clickAndCollect.collectionPointDates', []);
  const analytics = selectClickAndCollectStoreAnalytics(
    collectionPoint,
    searchTerm,
    collectionDates,
    selectedIndex,
  );

  await dispatch({
    type: SELECT_COLLECTION_POINT,
    collectionPoint,
    analytics,
    condensed,
    savedCollectionView,
  });

  const firstAvailableDate = collectionDates.find(date => date.isAvailable);

  if (firstAvailableDate) {
    // Collect+ stores don't show a calendar but we get dates anyway for fulfilmentOfferId reasons
    // then 'select' the first available date for the customer
    dispatch(silentlySelectCollectionDate(firstAvailableDate.date));
  }

  if (refresh) {
    return;
  }

  if (!getState().router?.location?.pathname.includes(routeConstants.CLICK_AND_COLLECT_STORE_SELECTED)) {
    dispatch(push(routeConstants.CLICK_AND_COLLECT_STORE_SELECTED));
  }

  if (shouldRecordDeliveryTimings) {
    dispatch(recordCollectionDateTimings());
  }
};

export const clearCollectionPointSelection = () => ({
  type: CLEAR_COLLECTION_POINT_SELECTION,
});

export const setCollectionPointViewType = activeViewType => async (dispatch) => {
  const url = activeViewType === VIEW_TYPE_MAP ?
    routeConstants.CLICK_AND_COLLECT_SEARCH_MAP : routeConstants.CLICK_AND_COLLECT_SEARCH_LIST;

  await dispatch(push(url));

  dispatch({
    type: SET_COLLECTION_POINT_VIEW_TYPE,
    activeViewType,
  });
};

export const closeMoreDetailsModal = () => (dispatch) => {
  dispatch(goBack());
};

export const openMoreDetailsModal = ({ id = '', route, condensedMapStoreListView = false }) => (dispatch) => {
  const path = `${route}/${STORE_DETAILS}`;
  dispatch(push(path, { id, condensedMapStoreListView }));
};

export const setActiveMapMarker = marker => ({
  type: SET_ACTIVE_MAP_MARKER,
  marker,
});

export const setActiveModalMapMarker = marker => ({
  type: SET_ACTIVE_MODAL_MAP_MARKER,
  marker,
});

export const setFindCollectionPointError = error => ({
  type: SET_FIND_COLLECTION_POINT_ERROR,
  error,
});

export const openSavedCollectionPointsModal = () => async (dispatch) => {
  dispatch(push(routeConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS));
};

export const changeClickAndCollectSelectedPoint = () => async (dispatch, getState) => {
  dispatch(triggerAnalyticsEvent(CHANGE_COLLECTION_POINT));

  const savedCollectionPoints = get(getState(), 'user.collectionPoints', []);

  if (savedCollectionPoints.length > 1) {
    dispatch(openSavedCollectionPointsModal());
    return;
  }

  const url = get(getState(), 'clickAndCollect.activeViewType') === VIEW_TYPE_MAP ?
    routeConstants.CLICK_AND_COLLECT_SEARCH_MAP : routeConstants.CLICK_AND_COLLECT_SEARCH_LIST;

  dispatch(push(url));
  dispatch(newCollectionPointSearch());
};

export const initSavedCollectionPoint = ({
  refresh,
  datesAlreadyLoaded,
  savedCollectionView = false,
} = {}) => (dispatch, getState) => {
  const state = getState();

  // TODO: What is relevance of collectionPointSearchComplete here?
  const collectionPointSearchComplete = get(state, 'clickAndCollect.collectionPointSearchComplete', false);
  const collectionPoint = determinePreSelectedCollectionPoint(get(state, 'user.collectionPoints', []));

  if (collectionPoint && !collectionPointSearchComplete) {
    return dispatch(selectCollectionPoint({
      collectionPoint,
      shouldRecordDeliveryTimings: true,
      datesAlreadyLoaded,
      savedCollectionView,
    }));
  }

  // refresh means customer encountered inconsistent order form error so we need to refresh selected
  // collection point dates to get up to date fulfilmentOfferIds
  if (refresh && !isEmpty(state.clickAndCollect.selectedCollectionPoint)) {
    return dispatch(selectCollectionPoint({ collectionPoint: state.clickAndCollect.selectedCollectionPoint }));
  }

  return null;
};

export const setClickCollectCountdownVisibility = visible => ({
  type: SET_CLICK_COLLECT_COUNTDOWN_VISIBILITY,
  visible,
});

export const setClickCollectCutOffPassed = cutOffPassed => ({
  type: SET_CLICK_COLLECT_CUT_OFF_PASSED,
  cutOffPassed,
});

export const handleCutOffPassedModalChangeDateClick = params => async (dispatch, getState) => {
  const {
    isPayment,
    modalVisible = true,
  } = params;

  const state = getState();
  const selectedCollectionPoint = get(state, 'clickAndCollect.selectedCollectionPoint', {});

  if (isPayment) {
    await dispatch(push(routeConstants.CLICK_AND_COLLECT_STORE_SELECTED));
  } else {
    dispatch(resetForm(COLLECTOR_DETAILS_FORM_ID));
  }

  if (modalVisible) {
    dispatch(setCutOffPassedModalVisibility(false));
  }

  dispatch(getCollectionPointDates({
    batchId: get(getState(), 'delivery.deliveries[0].id'),
    collectionPointId: selectedCollectionPoint.id,
  }));
};

export const deleteCollectionPoint = collectionPointId => ({
  type: DELETE_COLLECTION_POINT,
  request: client => client({
    path: URL_DELETE_COLLECTION_POINT(collectionPointId),
    config: {
      method: 'DELETE',
    },
  }),
});

export const showDeleteCollectionPointOverlay = id => ({
  type: SHOW_REMOVE_COLLECTION_POINT_OVERLAY,
  id,
});

export const hideDeleteCollectionPointOverlay = () => ({
  type: HIDE_REMOVE_COLLECTION_POINT_OVERLAY,
});

export const setGeoLocationSearchActiveStatus = active => ({
  type: SET_GEO_LOCATION_SEARCH_ACTIVE_STATUS,
  active,
});

export const onGoogleMapsScriptLoadSuccess = () => ({
  type: GOOGLE_MAPS_SCRIPT_LOADED,
});

export const onGoogleMapsScriptLoading = () => ({
  type: GOOGLE_MAPS_SCRIPT_LOADING,
});

export const collectionCutOffPassedDuringSubmitOrder = () => async (dispatch, getState) => {
  const ownerId = getState().clickAndCollect.selectedCollectionPoint?.ownerId;
  const isCollectPlusRelatedOwners = isCollectPlusRelatedType(ownerId);

  if (isCollectPlusRelatedOwners) {
    // we only get one date for Collect+ so we need to get a new date
    await dispatch(getAndSelectNewCollectionDate());
  }

  const state = getState();

  const availableDates = get(state, 'clickAndCollect.collectionPointDates', []).filter(({ isAvailable }) => isAvailable);
  const targetDate = isCollectPlusRelatedOwners ? availableDates[0] : availableDates[1];

  if (isUndefined(targetDate)) {
    // no more available dates so fake an error response to avoid a pointless API call
    return dispatch({
      type: `${PUT_CC_DELIVERY_DETAILS_SILENTLY}.FAILED`,
    });
  }

  const fulfilmentOfferId = targetDate.fulfilmentOfferId;
  const collectorDetails = state.clickAndCollect.collectorDetails;
  const giftMessageLines = state.clickAndCollect.giftMessageLines;

  const body = {
    collectorDetails,
    fulfilment: {
      fulfilmentOfferId,
      giftMessageLines,
    },
  };

  return dispatch({
    type: PUT_CC_DELIVERY_DETAILS_SILENTLY,
    request: client => client({ path: URL_COLLECTION_DETAILS, config: { method: 'PUT', body } }),
  });
};

export const recordCollectionUnavailableImpressionId = () => (dispatch, getState) => {
  const {
    app: {
      recordedImpressionIds = [],
    } = {},
    config: {
      features = [],
    } = {},
  } = getState() ?? {};

  const collectionUnavailableFeature = features?.find(
    feature => feature.id === featureConstants.COLLECTION_UNAVAILABLE_TAB,
  ) ?? {};

  const collectionUnavailableFeatureImpressionId = collectionUnavailableFeature.impressionId;
  if (
    collectionUnavailableFeatureImpressionId &&
    !recordedImpressionIds.includes(collectionUnavailableFeatureImpressionId)
  ) return dispatch(recordImpressions(collectionUnavailableFeatureImpressionId));

  return null;
};
