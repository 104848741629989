// Types
import { RootState, AppDispatch } from 'types/RootState.types';
import { FeatureFlagType } from 'types/FeatureFlag.types';

// Config
import userConstants from '../../../constants/userConstants';
import featureConstants from '../../../constants/featureConstants';
import { isFeatureActive } from '../../reducers/config/configReducer';
import { recordImpressions } from '../app/appActions';

type DeliveryPageResponseType = {
  result?: {
    customer?: {
      preferences?: {
        fulfilmentChoice?: string;
      };
    };
  };
};

export const recordPreferredDeliveryChoiceImpression = () => (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  const {
    app: {
      recordedImpressionIds = [],
    } = {},
    config: {
      features = [],
    } = {},
  } = getState() ?? {};

  const preferredDeliveryChoiceEnabledFeature = features?.find(
    (feature: FeatureFlagType) => feature.id === featureConstants.PREFERRED_DELIVERY_CHOICE,
  ) ?? {} as FeatureFlagType;

  const preferredDeliveryChoiceEnabledFeatureImpressionId = preferredDeliveryChoiceEnabledFeature.impressionId;
  if (
    preferredDeliveryChoiceEnabledFeatureImpressionId &&
    !recordedImpressionIds.includes(preferredDeliveryChoiceEnabledFeatureImpressionId)
  ) dispatch(recordImpressions(preferredDeliveryChoiceEnabledFeatureImpressionId));
};

export const shouldSetPreferredDeliveryChoice = (
  deliveryPageResponse: DeliveryPageResponseType,
) => (dispatch: AppDispatch, getState: () => RootState) => {
  const {
    result: {
      customer: {
        preferences: {
          fulfilmentChoice = '',
        } = {},
      } = {},
    } = {},
  } = deliveryPageResponse ?? {};

  const fulfilmentChoiceIsDelivery = fulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_DELIVERY;

  if (fulfilmentChoiceIsDelivery) dispatch(recordPreferredDeliveryChoiceImpression());

  const preferredDeliveryChoiceEnabled = isFeatureActive(getState(), featureConstants.PREFERRED_DELIVERY_CHOICE);

  return preferredDeliveryChoiceEnabled && fulfilmentChoiceIsDelivery;
};
