import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Design System
import validate from 'jl-design-system/form/validation/validate';

// Config
import { postPayments } from './paymentActions';
import { recordImpressions } from '../app/appActions';
import {
  putDeliveryDetails,
  putDeliveryAddress,
  getDeliveryMethods,
} from '../delivery/deliveryActions';
import { isApps } from '../../reducers/app/appReducer';
import { isFeatureActive } from '../../reducers/config/configReducer';
import deliveryConstants, { fulfilmentTypes } from '../../../constants/deliveryConstants';
import featureConstants from '../../../constants/featureConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import {
  SHOW_EXPRESS_PAYMENTS,
  EXPRESS_PAYMENTS_FAILED,
  INIT_EXPRESS_PAYMENT,
  EXPRESS_PAYMENTS_VALIDATION_FAILED,
} from '../../../constants/actionConstants';
import {
  getFormattedAddressFromApplePay,
  countryCodeIsInternational,
} from '../../../utils/address/addressHelpers';
import { getDeliveryAddressFormConfig } from '../../../utils/form/configs/deliveryAddress';

export const handleExpressFailure = response => ({
  type: EXPRESS_PAYMENTS_FAILED,
  response,
});

export const isFailure = action => action?.type.includes('FAILED');

export const handleApplePayExpress = ({
  shippingContact = {},
  payerPhone,
} = {}) => async (dispatch, getState) => {
  dispatch({
    type: INIT_EXPRESS_PAYMENT,
  });

  const formattedAddress = getFormattedAddressFromApplePay(shippingContact, payerPhone);

  if (countryCodeIsInternational(formattedAddress.address?.countryCode)) {
    return dispatch(handleExpressFailure({
      type: EXPRESS_PAYMENTS_VALIDATION_FAILED,
    }));
  }

  // validate shipping address
  const validateAddressResult = validate({
    ...formattedAddress.address,
    ...formattedAddress.addressee,
    phoneNumber: formattedAddress.phoneNumber,
  }, {
    config: getDeliveryAddressFormConfig({
      enableGBCounty: !!formattedAddress.address?.countyStateOrProvince,
    }),
  });

  if (!isEmpty(validateAddressResult)) {
    return dispatch(handleExpressFailure({
      validateAddressResult,
      type: EXPRESS_PAYMENTS_VALIDATION_FAILED,
    }));
  }

  const body = {
    addressDetails: {
      ...formattedAddress,
    },
  };

  const putDeliveryAddressResponse = await dispatch(putDeliveryAddress({ body, express: true }));
  if (isFailure(putDeliveryAddressResponse)) return dispatch(handleExpressFailure(putDeliveryAddressResponse));

  const id = get(putDeliveryAddressResponse, 'result.orderForm.deliveries[0].id');

  const methodsResponse = await dispatch(getDeliveryMethods({ express: true, id }));
  if (isFailure(methodsResponse)) return dispatch(handleExpressFailure(methodsResponse));

  const standardDeliveryMethod = methodsResponse?.result?.methods?.find(
    m => m.fulfilmentType === fulfilmentTypes.ONE_PERSON_STANDARD,
  ) ?? {};

  const {
    delivery: {
      deliveries = [],
    } = {},
  } = getState() ?? {};

  const deliveryDetailsPayload = [{
    deliveryId: deliveries[0]?.id,
    fulfilment: {
      fulfilmentOfferId: standardDeliveryMethod.fulfilmentOffers?.fulfilmentOfferId,
    },
  }];

  const putDeliveryDetailsResponse = await dispatch(putDeliveryDetails({ deliveryDetailsPayload, express: true }));
  if (isFailure(putDeliveryDetailsResponse)) return dispatch(handleExpressFailure(putDeliveryDetailsResponse));

  const postPaymentsResponse = await dispatch(postPayments());
  if (isFailure(postPaymentsResponse)) return dispatch(handleExpressFailure(postPaymentsResponse));

  return postPaymentsResponse;
};

export const showExpressPayments = ({
  isApplePayExpressEnabled = false,
  isPayPalPayExpressEnabled = false,
} = {}) => {
  const shouldShow = isApplePayExpressEnabled || isPayPalPayExpressEnabled;

  return ({
    type: SHOW_EXPRESS_PAYMENTS,
    shouldShow,
    ...shouldShow && {
      payOptionsAnalytics: {
        ...(isApplePayExpressEnabled && {
          [paymentTypeConstants.APPLE_PAY_EXPRESS]: {
            default: 1,
            enabled: 1,
          },
        }),
        ...(isPayPalPayExpressEnabled && {
          [paymentTypeConstants.PAYPAL_PAY_EXPRESS]: {
            default: 1,
            enabled: 1,
          },
        }),
      },
    },
  });
};

export const shouldShowExpressApplePay = ({ dispatch, state }) => {
  const {
    app: {
      recordedImpressionIds = [],
    } = {},
    config: {
      features = [],
    } = {},
    user: {
      collectionPointsSaved,
      isGuest,
    } = {},
    payment: {
      applePayCompatibilityChecksComplete: isApplePayExpressEnabled,
    } = {},
  } = state ?? {};

  if (!isApplePayExpressEnabled) return false;

  const expressApplePayFeature = features?.find(
    feature => feature.id === featureConstants.APPLE_PAY_EXPRESS_SIGNED_IN,
  ) ?? {};

  const expressApplePayFeatureImpressionId = expressApplePayFeature.impressionId;
  if (
    expressApplePayFeatureImpressionId &&
    !recordedImpressionIds.includes(expressApplePayFeatureImpressionId)
  ) dispatch(recordImpressions(expressApplePayFeatureImpressionId));

  const customerConditionsMet = isGuest
    || (isFeatureActive(state, featureConstants.APPLE_PAY_EXPRESS_SIGNED_IN) && !collectionPointsSaved);

  return customerConditionsMet;
};

export const shouldShowExpressPayPalPay = ({ dispatch, state }) => {
  const {
    app: {
      recordedImpressionIds = [],
    } = {},
    user: { isGuest } = {},
    payment: {
      payPalPayExpressCompatibilityChecksComplete: isPayPalPayExpressEnabled,
      payPalServiceUnavailable,
      getPayPalClientTokenCallActive,
    } = {},
    config: {
      features = [],
    } = {},
  } = state ?? {};

  const customerConditionsMet = isGuest;
  const clientTokenCallInactive = getPayPalClientTokenCallActive === false
    || getPayPalClientTokenCallActive === undefined;
  const serviceAvailable = payPalServiceUnavailable === false
    || payPalServiceUnavailable === undefined;

  const customerCouldUseFeature = customerConditionsMet && clientTokenCallInactive && serviceAvailable;

  if (customerCouldUseFeature) {
    const expressPayPayFeature = features?.find(
      feature => feature.id === featureConstants.ENABLE_PAYPAL_EXPRESS,
    ) ?? {};

    const expressPayPayFeatureImpressionId = expressPayPayFeature.impressionId;
    if (
      expressPayPayFeatureImpressionId &&
      !recordedImpressionIds.includes(expressPayPayFeatureImpressionId)
    ) dispatch(recordImpressions(expressPayPayFeatureImpressionId));
  }

  return customerCouldUseFeature && isPayPalPayExpressEnabled;
};

export const shouldShowExpressPaymentMethods = () => (dispatch, getState) => {
  const state = getState() ?? {};

  const {
    delivery: {
      deliveries = [],
    } = {},
    orderForm: {
      getPaymentPageComplete,
    } = {},
  } = state ?? {};

  const isSingleBatchOneMan = !!(deliveries.length === 1 &&
    deliveries.find(delivery => delivery.type === deliveryConstants.ONE_MAN));

  const isApplePayExpressEnabled = shouldShowExpressApplePay({ dispatch, state });
  const isPayPalPayExpressEnabled = shouldShowExpressPayPalPay({ dispatch, state });

  if (
    isApps(state)
    || (!isApplePayExpressEnabled && !isPayPalPayExpressEnabled)
    || getPaymentPageComplete
    || !isSingleBatchOneMan
  ) return dispatch(showExpressPayments());

  return dispatch(showExpressPayments({ isApplePayExpressEnabled, isPayPalPayExpressEnabled }));
};
