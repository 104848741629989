import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { go } from 'connected-react-router';
import cx from 'classnames';

// Design System
import { Modal } from 'jl-design-system/components/modal/Modal';
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'jl-design-system/elements/button/Button';

// Types
import { AppDispatch } from 'types/RootState.types';
import { PaymentCardDeleteProps } from './PaymentCardDelete.types';

// Config
import paymentCardDeleteState from './PaymentCardDelete.state';
import { showPaymentCardDeleteModal, deletePaymentCard } from '../../../../redux/actions/payment/paymentActions';
import { getLast4Digits } from '../../../../utils/helpers/cardDetails';
import cardLogos, { cardTypeText } from '../../../../utils/helpers/cardLogos';
import { disabledEscKeyHandler } from '../../../../utils/helpers/eventKeyActions';
import { useWindowSize } from '../../../../context/window/WindowSizeContext';

// Components
import BodyText from '../../../body-text';
import Container from '../../../container';

// Styles
import styles from './payment-card-delete.scss';

const PaymentCardDelete = ({
  disabled = false,
  routerActions = { go },
}: PaymentCardDeleteProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    paymentCardDeleteApiCallActive,
    selectedPaymentCard,
    shouldShowPaymentCardDeleteModal,
  } = useSelector(paymentCardDeleteState);
  const { isDesktop } = useWindowSize();

  if (!selectedPaymentCard) {
    return null;
  }

  useEffect(() => {
    document.addEventListener('keydown', disabledEscKeyHandler);

    return () => {
      document.removeEventListener('keydown', disabledEscKeyHandler);
    };
  }, []);

  const { id, obfuscatedPan = '', type } = selectedPaymentCard;

  const onClickHandler = () => dispatch(showPaymentCardDeleteModal());
  const onCloseHandler = () => dispatch(routerActions.go(-1));
  const last4Digits = getLast4Digits(obfuscatedPan);
  const onDeleteHandler = () => dispatch(deletePaymentCard(id, last4Digits));

  return (
    <>
      <TertiaryButton
        data-test="payment-card-delete-button"
        disabled={disabled}
        onClick={onClickHandler}
        small
      >
        Delete card
      </TertiaryButton>

      {shouldShowPaymentCardDeleteModal && (
        <Modal
          className={cx(styles.modal, 'max-w-md', {
            [styles.isProcessing]: paymentCardDeleteApiCallActive,
          })}
          footer={false}
          isOpen
          onClose={onCloseHandler}
          title="Delete payment card"
        >
          <Container>
            <BodyText marginBottom="2" testId="payment-card-delete-confirm-message">
              <strong>Are you sure you want to delete this payment card?</strong>
            </BodyText>

            {type && (
              <Container flex={{ alignItems: 'center' }}>
                <img
                  alt={type}
                  aria-hidden="true"
                  className={styles.logo}
                  src={cardLogos(type)}
                />
                <span data-test="payment-card-delete-card-details">
                  {cardTypeText(type)} ending in {last4Digits}
                </span>
              </Container>
            )}

            <Container
              flex={{
                alignItems: 'center',
                flexDirection: isDesktop ? 'row' : 'col-reverse',
                flexWrap: 'wrap',
                justifyContent: isDesktop ? 'end' : 'start',
              }}
              marginTop="3"
            >
              <SecondaryButton
                data-test="payment-card-delete-cancel"
                disabled={paymentCardDeleteApiCallActive}
                inline={isDesktop}
                onClick={onCloseHandler}
              >
                No, cancel
              </SecondaryButton>
              <PrimaryButton
                data-test="payment-card-delete-confirm"
                inline={isDesktop}
                onClick={onDeleteHandler}
                submitting={paymentCardDeleteApiCallActive}
              >
                Yes, delete
              </PrimaryButton>
            </Container>
          </Container>
        </Modal>
      )}
    </>
  );
};

export default PaymentCardDelete;
