/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import cx from 'classnames';

// Types
import { LoadingElementProps } from './LoadingElement.types';

// Styles
import styles from '../loading-wrapper.scss';

const LoadingElement = ({ className, type = 'light' }: LoadingElementProps) => (
  <div
    className={
      cx(styles.loadingElement, className, {
        [styles.loadingPlaceholderLight]: type === 'light',
        [styles.loadingPlaceholderDarker]: type === 'dark',
      })
    }
    data-test="loading-element"
  />
);

export default LoadingElement;
