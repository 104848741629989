import React, { useState } from 'react';

// Design System
import { Modal } from 'jl-design-system/components/modal/Modal';
import { IconButton } from 'jl-design-system/elements/button/IconButton';
import MagnifyGlassIcon from 'jl-design-system/elements/icons-jb/Magnify24PxOutlined';

// Types
import { ProductImageWithMagnifierProps } from './ProductImageWithMagnifier.types';

// Config
import thumbnail from '../../utils/image/thumbnail';

// Components
import Container from '../container';
import LoadingWrapper from '../loading-wrapper';

// Styles
import styles from './product-image-with-magnifier.scss';

const ProductImageWithMagnifier = ({
  imageUrl,
  name,
  showMagnifyingIcon = false,
}: ProductImageWithMagnifierProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const setModalOpenHandler = () => setModalOpen(!isModalOpen);

  const imageLoadedHandler = () => {
    setIsLoaded(true);
  };

  return (
    <>
      <Container className={styles.itemImage} marginRight="2" relative>
        <Container height="full" margin="0" relative tag="figure">
          <img
            alt={name}
            aria-hidden="true"
            className={isLoaded ? styles.isLoaded : ''}
            data-test="product-item-image"
            loading="lazy"
            onLoad={imageLoadedHandler}
            src={`${thumbnail(imageUrl)}`}
          />
          {showMagnifyingIcon && (
            <IconButton
              IconComponent={MagnifyGlassIcon}
              ariaLabel="Zoom in product image"
              className={styles.floatingIcon}
              onClick={setModalOpenHandler}
              size="small"
              variant="filled-light"
            />
          )}
          <LoadingWrapper className={styles.loadingWrapper} isLoading />
        </Container>
      </Container>

      {isModalOpen && (
        <Modal
          footer={false}
          headerType="empty"
          isOpen={isModalOpen}
          onClose={setModalOpenHandler}
          variant="full-screen"
        >
          <div data-test="magnified-product-item-image-wrapper">
            <img
              alt={`magnified-${name}`}
              aria-hidden="true"
              data-test="magnified-product-item-image"
              src={imageUrl}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductImageWithMagnifier;
