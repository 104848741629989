import env from 'jl-design-system/utils/env/env';
import redirect from 'jl-design-system/utils/redirect/redirect';

import { clearStorage } from '../../../utils/storage/storage';
import dataLinkConstants from '../../../constants/dataLinkConstants';
import { isApps } from '../../reducers/app/appReducer';

export const handbackTo = (url, target) => async (dispatch, getState) => {
  const urlSansProtocol = url.substr(url.indexOf('//') + 2);
  const pathIndex = urlSansProtocol.indexOf('/');
  const path = pathIndex >= 0 ? urlSansProtocol.substr(pathIndex) : '/';

  // remove all stashed values
  clearStorage({ local: false });

  // get handback URL if not Apps
  if (!isApps(getState())) {
    // for local development, return to basket links should redirect to localhost
    if (url.includes('/basket') && env.isClientLocal) {
      redirect('/');
      return undefined;
    }

    const handbackUrl = path === '/' ? `${window.publicJLSiteDomain}/#homepage` : `${window.publicJLSiteDomain}${path}`;

    if (target === '_blank') {
      return handbackUrl;
    }

    redirect(handbackUrl);
    return undefined;
  }
};

export const dataAction =
  (action, options = {}) =>
  async (dispatch) => {
    if (action !== dataLinkConstants.HANDBACK) {
      return;
    }

    const { url, target } = options;
    const tab = target === '_blank' ? window.open() : undefined;
    const href = await dispatch(handbackTo(url, target));

    if (tab && href) {
      tab.location.href = href;
    }
  };
