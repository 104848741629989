import {
  ANALYTICS_ADD_NEW_CARD_CLICK,
  ANALYTICS_AMEND_BASKET_MODAL_OPEN,
  ANALYTICS_AUTH0_REGISTRATION_CALLBACK_SUCCESS,
  ANALYTICS_BATCH_ERROR,
  ANALYTICS_BATCH_ERROR_ITEMS_REMOVED,
  ANALYTICS_COLLECTION_POINT_CUT_OFF_MODAL_SHOWN,
  ANALYTICS_COLLECTION_POINT_DOWN,
  ANALYTICS_COLLECTION_POINT_SUCCESS,
  ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED,
  ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED,
  ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED,
  ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED,
  ANALYTICS_DELIVERY_INSTRUCTIONS_FOCUSED,
  ANALYTICS_DELIVERY_INSTRUCTIONS_SELECTED,
  ANALYTICS_DELIVERY_OPTIONS,
  ANALYTICS_EDIT_BASKET_MODAL_OPEN,
  ANALYTICS_EXPAND_BASKET_BUTTON_CLICKED,
  ANALYTICS_FIND_COLLECTION_POINT_FAILED,
  ANALYTICS_FIND_COLLECTION_POINT_WITH_GEO_LOCATION,
  ANALYTICS_GET_COLLECTION_POINT_DETAILS,
  ANALYTICS_GIFT_MESSAGE_POPULATED,
  ANALYTICS_GIFT_OPTIONS_CHANGE_TO_PREPAID,
  ANALYTICS_GIFT_RECEIPT_SELECTED,
  ANALYTICS_JOIN_MY_JOHN_LEWIS,
  ANALYTICS_JOIN_MY_JOHN_LEWIS_CANCELLED,
  ANALYTICS_CREATE_ACCOUNT_CANCELLED,
  ANALYTICS_LEAVE_CHECKOUT_MODAL_SHOWN,
  ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK,
  ANALYTICS_MARKETING_OPT_IN,
  ANALYTICS_MY_JL_JOIN_MODAL_OPEN,
  ANALYTICS_OPEN_APPLE_PAY_PAYMENT_SHEET,
  ANALYTICS_ORDER_COMPLETED_WITH_SAVED_CARD,
  ANALYTICS_OUT_OF_STOCK_MODAL_OPEN,
  ANALYTICS_PAGE_NOT_FOUND,
  ANALYTICS_PARTNER_DISCOUNT_UNAVAILABLE_MODAL_OPEN,
  ANALYTICS_PAYMENT_CARD_DELETE_OPEN,
  ANALYTICS_PAYMENT_OPTION_SELECTED,
  ANALYTICS_PAYMENT_SHOW_3D_SECURE,
  ANALYTICS_PLACE_ORDER_AND_PAY_CLICK,
  ANALYTICS_PLACE_ORDER_AND_PAY_CLICK_START,
  ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED,
  ANALYTICS_PREPAID_GIFTCARD_MESSAGE,
  ANALYTICS_PUT_DELIVERY_DETAILS,
  ANALYTICS_REWARDS_TOOLTIP,
  ANALYTICS_REWARDS_TOGGLE,
  ANALYTICS_SAVE_GUEST_ORDER,
  ANALYTICS_SAVE_GUEST_ORDER_SUCCESS,
  ANALYTICS_SAVE_NEW_CARD_CHECKED,
  ANALYTICS_SAVE_NEW_CARD_UNCHECKED,
  ANALYTICS_SAVED_CARD_DETAILS_TOGGLED,
  ANALYTICS_SET_SUBMIT_FAILED,
  ANALYTICS_SHOW_AGE_CHECK_MODAL,
  ANALYTICS_SHOW_COLLECTION_HELP,
  ANALYTICS_SHOW_COLLECTION_HELP_REMOVE,
  ANALYTICS_SHOW_PERSONALISATION_HELP,
  ANALYTICS_SHOW_PERSONALISATION_CONFIRMATION,
  ANALYTICS_SINGLE_SIGN_ON_LOGIN,
  ANALYTICS_SUBMIT_ORDER_ERROR,
  ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA,
  ANALYTICS_VOUCHER_UNDERSPEND_MODAL_OPEN,
  ANALYTICS_VOUCHER_UNDERSPEND_REMOVED,
  APPLE_PAY_PAYMENT_CANCEL,
  APPLE_PAY_PAYMENT_FAILURE,
  APPLE_PAY_SELECTED,
  APPLY_GIFT_CARD,
  APPLY_GIFT_VOUCHER,
  APPLY_PROMO_CODE,
  BASKET_ITEM_OUT_OF_STOCK,
  BIN_NUMBER_UPDATE,
  CARD_PAYMENT,
  CLEARPAY_ENABLED_STATUS,
  GET_DELIVERY_METHODS,
  GET_DELIVERY_PAGE,
  GET_LOGIN_PAGE,
  GET_ORDER_CONFIRMATION_PAGE,
  GET_MINIMAL_ORDER_CONFIRMATION_PAGE,
  GET_PAYMENT_PAGE,
  GIFT_OPTIONS_CHANGE_OPTION,
  GIFT_OPTIONS_MODAL_CLOSE,
  GIFT_OPTIONS_MODAL_OPEN,
  GOOGLE_PAY_ENABLED,
  GOOGLE_PAY_DEFAULT,
  HAND_OVER,
  INIT_PAYMENT_PAGE,
  JOIN_MY_JOHN_LEWIS,
  KLARNA_ENABLED_STATUS,
  LOGIN_CREDS,
  NEW_COLLECTION_POINT_SEARCH,
  OCP_SUBMIT_3DS_METHOD_ERROR,
  PAYMENT_CARD_DELETE,
  POST_DELIVERY_PAGE,
  PUT_CC_DELIVERY_DETAILS,
  PUT_DELIVERY_ADDRESS,
  PUT_ITEMS_QUANTITIES,
  REDUX_FORM_SET_SUBMIT_FAILED,
  REMOVE_BASKET_ITEMS,
  REMOVE_GIFT_OPTION,
  ROUTER_LOCATION_CHANGE,
  SAVED_CARD_SELECTED,
  SELECT_COLLECTION_POINT,
  SESSION_EXPIRED,
  SET_CAN_MAKE_APPLE_PAY_PAYMENTS,
  SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS,
  SET_CHECK_ELIGIBILITY_PROCESSING,
  SET_FIND_COLLECTION_POINT_ERROR,
  SET_MYJL_MODAL_CONTENT_INDEX,
  SET_OCP_3DS_FAIL_ERROR,
  SHOW_POS_CREDIT_BASKET_AMEND_MODAL,
  SHOW_POS_CREDIT_GET_READY_MODAL,
  TRANSACTION_STATUS_UPDATE,
  USER_AGE_CHECK,
  ANALYTICS_UPDATE_PAGEINFO,
  PRINT_ORDER_BUTTON_CLICKED,
  REMOVE_PROMO_CODE,
  CHANGE_COLLECTION_POINT,
  APPLY_REWARDS,
  REMOVE_REWARDS,
  SHOW_EXPRESS_PAYMENTS,
  ALLOW_INIT_DELIVERY_PAGE_CALLS,
  ANALYTICS_OPEN_APPLE_PAY_EXPRESS_PAYMENT_SHEET,
  AGE_VERIFICATION_SKIP_RESTRICTED,
  AGE_VERIFICATION_VERIFY_AGE_CLICK,
  AGE_VERIFICATION_SUCCESS,
  AGE_VERIFICATION_FAILED,
  AGE_VERIFICATION_BLADED_ARTICLE,
  AGE_VERIFICATION_FAILED_RETURN_TO_BASKET_CLICK,
  THRESHOLD_BAR_KEEP_SHOPPING_CLICK,
  THRESHOLD_BAR_SHOWN,
  THRESHOLD_BAR_SHOWN_ON_QUANTITY_EDIT,
  PRICE_PROMISE_GLOBAL_INTERACTION,
} from '../../constants/actionConstants';

import { GIFT_OPTIONS } from '../../constants/giftOptionsConstants';
import routeConstants from '../../constants/routeConstants';
import errorCodeConstants from '../../constants/errorCodeConstants';
import userConstants from '../../constants/userConstants';

/**
 * Used by getErrorArray() -> getErrorArray.js
 * Update with any new error types required by analytics
 */
export const validErrorTypes = [
  'checkout:system',
  'checkout:form',
  'checkout:form:promo',
  'checkout:3ds',
  'checkout:paypal',
  'checkout:geolocation',
  'checkout:delivery',
  'checkout:agerestriction',
  'checkout:applepay:cancelled',
  'checkout:applepay:failed',
  'checkout:payment',
  'checkout:rewards',
];

/**
 * For each route in our App we need to specify the pageName string here
 * Note: Each colon separated chunk of this string is used to create the analytics breadcrumb array
 */
export const pageNamesByRoute = {
  [`${routeConstants.LOGIN}`]: 'jl:checkout:login',
  [`${routeConstants.DELIVERY}`]: 'jl:checkout:delivery',
  [`${routeConstants.DELIVERY_ADDRESS_BOOK}`]: 'jl:checkout:delivery:addressbook',
  [`${routeConstants.DELIVERY_ADDRESS_BOOK_NEW}`]: 'jl:checkout:delivery:addressbook:new',
  [`${routeConstants.DELIVERY_ADDRESS_BOOK_EDIT}`]: 'jl:checkout:delivery:addressbook:edit',
  [`${routeConstants.CLICK_AND_COLLECT}`]: 'jl:checkout:delivery',
  [`${routeConstants.PAYMENT}`]: 'jl:checkout:payment',
  [`${routeConstants.ORDER_CONFIRMATION}`]: 'jl:checkout:orderreceipt',
  [`${routeConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS}`]: 'jl:checkout:collectionpoint:addressbook',
  [`${routeConstants.SESSION_EXPIRED}`]: 'jl:checkout:session expired',
  [`${routeConstants.LEAVE_CHECKOUT}`]: 'jl:checkout:leave checkout',
  [`${routeConstants.JOIN_MY_JL}`]: 'jl:checkout:join my john lewis',
  [routeConstants.CLICK_AND_COLLECT_SEARCH_LIST]: 'jl:checkout:delivery',
  [routeConstants.CLICK_AND_COLLECT_STORE_SELECTED]: 'jl:checkout:delivery',
};

/**
 * If your analytics requirement is to dispatch an event when there is a route change (ie a page view)
 * Add a key matching your route name here,
 * It's value must be an object and props can be added to help configure the analyticsData object (see CONFIGURING below)
 */
const loginRoutesConfig = {
  [`${routeConstants.LOGIN}`]: {},
};

const partnerDiscountConditions = {
  condition: {
    prop: 'eventDetails.partnerDiscountEnabled',
    value: {
      true: {
        eventDetails: {
          action: 'partnershipToggle',
          type: 'on',
        },
        replace: [
          ['customer.customerInfo.partnershipDiscount', 1],
        ],
      },
      false: {
        eventDetails: {
          action: 'partnershipToggle',
          type: 'off',
        },
        replace: [
          ['customer.customerInfo.partnershipDiscount', 0],
        ],
      },
      default: {
        eventDetails: {
          action: 'pageload',
        },
      },
    },
  },
};

const paymentRoutesConfig = {
  [`${routeConstants.PAYMENT}`]: {
    condition: {
      prop: 'route.search',
      value: {
        '': {},
        '?3dsecure=unsuccessful': {
          eventName: 'dataStateChange',
          errorType: 'checkout:3ds',
          errorMessage: "Sorry, we've been unable to authenticate your payment",
          replaceByProp: [
            ['checkout.payment.order.bn', 'checkout.payment.order.bn'],
          ],
        },
      },
    },
  },
};

const deliveryRoutesConfig = {
  [`${routeConstants.DELIVERY}`]: partnerDiscountConditions,
  [`${routeConstants.CLICK_AND_COLLECT}`]: partnerDiscountConditions,
  [routeConstants.CLICK_AND_COLLECT_SEARCH_LIST]: partnerDiscountConditions,
  [routeConstants.CLICK_AND_COLLECT_STORE_SELECTED]: partnerDiscountConditions,
};

const deliveryAddressBookRoutesConfig = {
  [`${routeConstants.DELIVERY_ADDRESS_BOOK}`]: { pageName: 'jl:checkout:delivery:addressbook' },
  [`${routeConstants.DELIVERY_ADDRESS_BOOK_NEW}`]: { pageName: 'jl:checkout:delivery:addressbook:new' },
  [`${routeConstants.DELIVERY_ADDRESS_BOOK_EDIT}`]: { pageName: 'jl:checkout:delivery:addressbook:edit' },
};

const collectionPointsRoutesConfig = {
  [`${routeConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS}`]: {
    pageName: 'jl:checkout:collectionpoint:addressbook',
  },
};

const orderConfirmationRoutesConfig = {
  [`${routeConstants.ORDER_CONFIRMATION}`]: {
    condition: {
      prop: 'result.createAccountPrompt.visible',
      value: {
        true: {
          replaceByProp: [
            ['checkout.applePayExpress', 'checkout.applePayExpress'],
            ['checkout.payment.order.bn', 'checkout.payment.order.bn'],
            ['checkout.payment.order.authenticationStatus', 'checkout.payment.order.authenticationStatus'],
            ['checkout.payment.order.newday3DSFailure', 'checkout.payment.order.newday3DSFailure'],
          ],
          jlAccountSignupOption: 1,
        },
        default: {
          replaceByProp: [
            ['checkout.applePayExpress', 'checkout.applePayExpress'],
            ['checkout.payment.order.bn', 'checkout.payment.order.bn'],
            ['checkout.payment.order.authenticationStatus', 'checkout.payment.order.authenticationStatus'],
            ['checkout.payment.order.newday3DSFailure', 'checkout.payment.order.newday3DSFailure'],
          ],
          jlAccountSignupOption: 0,
        },
      },
    },
  },
  [`${routeConstants.JOIN_MY_JL}`]: {
    pageName: 'jl:checkout:join my john lewis',
  },
};

const getDeliveryMethodsConfig = {
  conditions: [
    {
      condition: {
        prop: 'result.deliveryMethods[0].deliveryDetailsUnavailable',
        value: {
          true: {
            eventDetails: {
              eventName: 'deliveryInteraction',
              type: 'standard specialist delivery',
            },
          },
        },
      },
    },
    {
      condition: {
        prop: 'result.deliveryMethods[0].bookingType',
        value: {
          NIGHTFREIGHT: {
            eventDetails: {
              eventName: 'deliveryInteraction',
              type: 'standard specialist delivery',
            },
          },
        },
      },
    },
  ],
};

const removeItemsConfig = {
  action: 'global.interaction',
  type: 'checkout',
  additionalInfo: {},
  properties: {},
  includeActionFields: ['products'],
};

/**
 * CONFIGURING THE ANALYTICS DATA OBJECT
 * If you want an analytics event to be dispatched for a given redux ACTION the returned value of the matching key in actionsConfig MUST be an object
 * The object can be empty OR include props that can be used to configure the analytics data (window.jlData) object
 * [eventName]: By default the eventName is dataStateChange || dataCheckoutError (if errorType is defined), but will be overridden if specified here
 * [eventDetails]: An optional object that is used in the construction of the new CustomEvent() object that we dispatch with every analytics trigger
 *                 eg: new CustomEvent(eventName, { detail: {...eventDetails} });
 * [pageName]: By default the pageName of the current App route is used, but will be overridden if specified here
 * [errorType]: The type of error specified will determine what analyticsData.error information is returned
 * [errorMessage]: Single errors (ie not form field errors) will default to use the error message included in the redux ACTION, but can be overridden if specified here
 * [condition]: if specified in an actionsConfig object, ACTION[condition.prop] is evaluated against ACTION[condition.value]
 *              if it evaluates to true, the actionsConfig object is returned minus the condition prop
 *              if condition.value is an object ACTION[condition.prop] is evaluated against the keys in condition.value and the matching object returned
 *              - conditions can be nested as deeply as required -
 * [detailsToKeep]: An array of property names that want to be passed along with the event, relating to data passed in the action
 * [any other props]: if specified, props apart from those mentioned above will be appended, as is, to the analyticsData.page.pageInfo object
 */

export const actionsConfig = {

  [ROUTER_LOCATION_CHANGE]: {
    condition: {
      prop: 'route.pathname',
      value: {
        ...loginRoutesConfig,
        ...paymentRoutesConfig,
        ...deliveryRoutesConfig,
        ...orderConfirmationRoutesConfig,
        ...deliveryAddressBookRoutesConfig,
        ...collectionPointsRoutesConfig,
      },
    },
  },

  [`${PUT_ITEMS_QUANTITIES}.SUCCESS`]: {
    eventDetails: {
      action: 'checkoutQuantityAmended',
    },
  },

  [`${PUT_ITEMS_QUANTITIES}.FAILED`]: {
    errorType: 'checkout:form',
    errorMessage: "Sorry, we couldn't amend the quantity. Please return to basket.",
  },

  [`${REMOVE_BASKET_ITEMS}.FAILED`]: {
    errorType: 'checkout:form',
    errorMessage: "Sorry, we couldn't remove the item. Please return to basket.",
  },

  [`${REMOVE_BASKET_ITEMS}.SUCCESS`]: {
    eventName: 'dataStateChange',
    condition: {
      prop: 'isTradeIn',
      value: {
        true: {
          eventDetails: {
            subType: 'trade in remove',
            ...removeItemsConfig,
          },
        },
        default: {
          eventDetails: {
            subType: 'remove item',
            ...removeItemsConfig,
          },
        },
      },
    },
  },

  [SESSION_EXPIRED]: {
    pageName: 'jl:checkout:session expired',
  },

  [REDUX_FORM_SET_SUBMIT_FAILED]: {
    errorType: 'checkout:form',
  },

  [ANALYTICS_SET_SUBMIT_FAILED]: {
    errorType: 'checkout:form',
  },

  [GIFT_OPTIONS_CHANGE_OPTION]: {
    condition: {
      prop: 'contentId',
      value: {
        [GIFT_OPTIONS.GIFT_CARD]: {
          pageName: 'jl:checkout:payment:gift card',
        },
        [GIFT_OPTIONS.GIFT_VOUCHER]: {
          pageName: 'jl:checkout:payment:gift voucher',
        },
      },
    },
  },

  [GIFT_OPTIONS_MODAL_CLOSE]: {
    pageName: 'jl:checkout:payment',
  },

  [GIFT_OPTIONS_MODAL_OPEN]: {
    pageName: 'jl:checkout:payment:gift payment type',
  },

  [ANALYTICS_GIFT_RECEIPT_SELECTED]: {
    condition: {
      prop: 'giftReceiptSelected',
      value: {
        true: {
          eventName: 'dataStateChange',
          eventDetails: {
            action: 'checkout.giftOptionClick',
            value: true,
          },
          replace: [
            ['checkout.payment.order.giftOptionChecked', true],
          ],
        },
        default: {
          eventName: 'dataStateChange',
          eventDetails: {
            action: 'checkout.giftOptionClick',
            value: false,
          },
          replace: [
            ['checkout.payment.order.giftOptionChecked', false],
          ],
        },
      },
    },
  },

  [ANALYTICS_GIFT_MESSAGE_POPULATED]: {
    condition: {
      prop: 'giftMessagePopulated',
      value: {
        true: {
          replace: [
            ['checkout.payment.order.giftOptionChecked', true],
            ['checkout.payment.order.giftOptionMessage', true],
          ],
        },
      },
    },
  },

  [`${GET_LOGIN_PAGE}.SUCCESS`]: {
    condition: {
      prop: 'route.pathname',
      value: {
        ...loginRoutesConfig,
      },
    },
  },

  [`${GET_LOGIN_PAGE}.FAILED`]: {
    errorType: 'checkout:system',
  },

  [ANALYTICS_COLLECTION_POINT_SUCCESS]: {
    eventDetails: {
      action: 'collectionPointSearch',
    },
    replace: ['checkout.batch.0.deliveryProposition'],
  },

  [SELECT_COLLECTION_POINT]: {
    condition: {
      prop: 'collectionPoint.isSaved',
      value: {
        true: {
          eventDetails: {
            action: 'savedcollectionpointselected',
          },
        },
        default: {
          eventDetails: {
            action: 'selectCollectionPoint',
          },
        },
      },
      replace: ['checkout.batch.0.deliveryProposition'],
    },
  },

  [NEW_COLLECTION_POINT_SEARCH]: {
    eventDetails: {
      action: 'newcollectionpointsearch',
    },
    replace: ['checkout.batch.0.deliveryProposition'],
  },

  [ANALYTICS_GET_COLLECTION_POINT_DETAILS]: {
    eventDetails: {
      action: 'selectCollectionPoint',
    },
    replace: ['checkout.batch.0.deliveryProposition'],
  },

  [`${GET_DELIVERY_PAGE}.SUCCESS`]: {
    condition: {
      prop: 'route.pathname',
      value: {
        ...deliveryRoutesConfig,
      },
    },
  },

  [`${POST_DELIVERY_PAGE}.SUCCESS`]: {
    condition: {
      prop: 'route.pathname',
      value: {
        ...deliveryRoutesConfig,
      },
    },
  },

  [ALLOW_INIT_DELIVERY_PAGE_CALLS]: {
    condition: {
      prop: 'route.pathname',
      value: {
        ...deliveryRoutesConfig,
      },
    },
  },

  [`${GET_DELIVERY_PAGE}.FAILED`]: {
    errorType: 'checkout:system',
  },

  [`${POST_DELIVERY_PAGE}.FAILED`]: {
    errorType: 'checkout:system',
  },

  [`${INIT_PAYMENT_PAGE}.SUCCESS`]: {
    conditions: [{
      condition: {
        prop: 'route.pathname',
        value: {
          ...paymentRoutesConfig,
        },
      },
    }, {
      condition: {
        prop: 'result.marketingPreferencesPromptVisible',
        value: {
          default: {
            replaceByProp: [
              ['customer.customerInfo.marketingSelections.optInBox', 'result.marketingPreferencesPromptVisible'],
            ],
          },
        },
      },
    }],
  },

  [SET_CARD_PAYMENT_3DSECURE_CANCELLED_STATUS]: {
    replaceByProp: [
      ['customer.customerInfo.threeDSFailureCount', 'threeDSFailureCount'],
      ['checkout.payment.order.newday3DSFailure', 'newdayCardType'],
    ],
  },

  [`${GET_PAYMENT_PAGE}.SUCCESS`]: partnerDiscountConditions,

  [`${INIT_PAYMENT_PAGE}.FAILED`]: {
    errorType: 'checkout:system',
  },

  [`${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`]: {
    conditions: [{
      condition: {
        prop: 'route.pathname',
        value: {
          ...orderConfirmationRoutesConfig,
        },
      },
    }, {
      condition: {
        prop: 'isClickCollectOrder',
        value: {
          true: {
            replaceByProp: [
              ['checkout.batch.0.deliveryProposition.0.collectionType', 'result.order.deliveries.0.fulfilment.collectionInfo.type'],
            ],
          },
        },
      },
    }],
  },

  [GET_MINIMAL_ORDER_CONFIRMATION_PAGE]: {
    condition: {
      prop: 'route.pathname',
      value: {
        ...orderConfirmationRoutesConfig,
      },
    },
  },

  [`${GET_ORDER_CONFIRMATION_PAGE}.FAILED`]: {
    errorType: 'checkout:system',
  },

  [SET_MYJL_MODAL_CONTENT_INDEX]: {
    condition: {
      prop: 'index',
      value: {
        0: {
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up',
        },
        1: {
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up:edit',
        },
        2: {
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up:new',
        },
      },
    },
  },

  [ANALYTICS_PAGE_NOT_FOUND]: {
    errorType: 'checkout:system',
    errorMessage: 'Sorry, this page is unavailable',
  },

  [`${LOGIN_CREDS}.FAILED`]: {
    condition: {
      prop: 'error.code',
      value: {
        [errorCodeConstants.SERVER_INTERNAL_ERROR]: {
          errorType: 'checkout:form',
        },
        // when a customer tries to re-enter checkout after emptying their basket
        [errorCodeConstants.ORDER_FORM_EMPTY]: {
          errorType: 'checkout:system',
        },
        // when a basket can't be found, possibly because their order form ID is no longer valid
        [errorCodeConstants.ORDER_FORM_NOT_FOUND]: {
          errorType: 'checkout:system',
        },
      },
    },

  },

  [`${HAND_OVER}.FAILED`]: {
    errorType: 'checkout:system',
  },

  [ANALYTICS_SINGLE_SIGN_ON_LOGIN]: {
    pageName: 'jl:checkout:login',
    eventDetails: {
      action: 'pageload',
    },
    sso: true,
  },

  [ANALYTICS_PAYMENT_SHOW_3D_SECURE]: {
    pageName: 'jl:checkout:paymentauthentication',
    eventDetails: {
      action: 'pageload',
    },
  },

  [ANALYTICS_DELIVERY_INSTRUCTIONS_SELECTED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout:delivery',
      subType: 'delivery instructions drop down: ${subType}',
      additionalInfo: {},
      product: {},
      stringVarsToReplace: ['subType'],
    },
  },

  [ANALYTICS_DELIVERY_INSTRUCTIONS_FOCUSED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout:delivery',
      subType: 'delivery instructions drop down',
      additionalInfo: {},
      product: {},
    },
  },

  [ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED]: {
    errorType: 'checkout:form',
    errorMessage: 'Your personal details have not been saved',
  },

  [ANALYTICS_CREATE_ACCOUNT_ORDER_NOT_LINKED]: {
    errorType: 'checkout:system',
    errorMessage: 'Your order has not been linked to your account',
  },

  [ANALYTICS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED]: {
    errorType: 'checkout:system',
    errorMessage: 'Your order has not been linked to your account and your details have not been saved',
  },

  [ANALYTICS_CREATE_ACCOUNT_PAYMENT_CARD_NOT_SAVED]: {
    errorType: 'checkout:form',
    errorMessage: 'Your payment details have not been saved',
  },

  [`${GET_DELIVERY_METHODS}.FAILED`]: {
    errorType: 'checkout:form',
  },

  'POST_DELIVERY_METHODS.SUCCESS': {
    conditions: [
      {
        condition: {
          prop: 'result.deliveryMethods[0].deliveryDetailsUnavailable',
          value: {
            true: {
              eventDetails: {
                eventName: 'deliveryInteraction',
                type: 'standard specialist delivery',
              },
            },
          },
        },
      },
      {
        condition: {
          prop: 'result.deliveryMethods[0].bookingType',
          value: {
            NIGHTFREIGHT: {
              eventDetails: {
                eventName: 'deliveryInteraction',
                type: 'standard specialist delivery',
              },
            },
          },
        },
      },
    ],
  },

  [`${PUT_DELIVERY_ADDRESS}.SUCCESS`]: getDeliveryMethodsConfig,
  [`${GET_DELIVERY_METHODS}.SUCCESS`]: getDeliveryMethodsConfig,

  [ANALYTICS_PUT_DELIVERY_DETAILS]: {
    eventDetails: {
      action: 'continueToPayment',
    },
    detailsToKeep: [
      'firstAvailableDay',
      'selectedDaysUntilDelivery',
      'deliveryMessage',
    ],
  },

  [`${PUT_CC_DELIVERY_DETAILS}.SUCCESS`]: {
    eventDetails: {
      action: 'continueToPayment',
    },
    detailsToKeep: [
      'firstAvailableDay',
      'clickandCollectType',
      'selectedDaysUntilDelivery',
      'deliveryMessage',
    ],
  },

  [ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA]: {
    replaceByProp: [
      ['checkout.payment', 'paymentAnalytics'],
      ['checkout.product', 'productAnalytics'],
    ],
  },

  [ANALYTICS_DELIVERY_OPTIONS]: {
    eventDetails: {
      action: 'deliveryInteraction',
    },
    replace: ['checkout.batch'],
  },

  [`${APPLY_GIFT_VOUCHER}.SUCCESS`]: {
    condition: {
      prop: 'isUndo',
      value: {
        true: {
          eventDetails: {
            action: 'addGiftVoucherPayment',
            additionalInfo: {
              undo: 'remove gift voucher',
            },
          },
        },
        default: {
          eventDetails: {
            action: 'addGiftVoucherPayment',
          },
        },
      },
    },
  },

  [`${APPLY_PROMO_CODE}.FAILED`]: {
    eventName: 'dataCheckoutError',
    errorType: 'checkout:form:promo',
    additionalProperties: {
      field: 'promoCode',
    },
    replaceByProp: [
      ['checkout.payment.order.promoCode', 'code'],
    ],
  },

  [`${APPLY_PROMO_CODE}.SUCCESS`]: {
    condition: {
      prop: 'isUndo',
      value: {
        true: {
          eventDetails: {
            action: 'applyPromotionCode',
            additionalInfo: {
              undo: 'remove promo code',
            },
          },
        },
        default: {
          eventDetails: {
            action: 'applyPromotionCode',
          },
        },
      },
    },
    replaceByProp: [
      ['checkout.payment.order.promoCode', 'code'],
    ],
  },

  [`${REMOVE_PROMO_CODE}.SUCCESS`]: {
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'remove promo code',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [ANALYTICS_SAVE_NEW_CARD_CHECKED]: {
    eventDetails: {
      action: 'checkout.newPaymentCard',
    },
    replace: [
      ['checkout.payment.order.newSavedCard', true],
    ],
  },

  [ANALYTICS_SAVE_NEW_CARD_UNCHECKED]: {
    eventDetails: {
      action: 'checkout.newPaymentCard',
    },
    replace: [
      ['checkout.payment.order.newSavedCard', false],
    ],
  },

  [ANALYTICS_ORDER_COMPLETED_WITH_SAVED_CARD]: {
    eventDetails: {
      action: 'checkout.savedPaymentCard',
    },
    replace: [
      ['checkout.receipt.order.savedPaymentCard', true],
    ],
  },

  [`${APPLY_GIFT_VOUCHER}.FAILED`]: {
    condition: {
      prop: 'error.code',
      value: {
        [errorCodeConstants.GIFT_VOUCHER_SERVICE_UNAVAILABLE]: {
          errorType: 'checkout:system',
        },
        default: {
          errorType: 'checkout:form',
        },
      },
    },
  },

  [`${APPLY_GIFT_CARD}.SUCCESS`]: {
    condition: {
      prop: 'isUndo',
      value: {
        true: {
          eventDetails: {
            action: 'addGiftCardPayment',
            additionalInfo: {
              undo: 'remove gift card',
            },
          },
        },
        default: {
          eventDetails: {
            action: 'addGiftCardPayment',
          },
        },
      },
    },
  },

  [`${APPLY_GIFT_CARD}.FAILED`]: {
    condition: {
      prop: 'error.code',
      value: {
        [errorCodeConstants.GIFT_CARD_SERVICE_UNAVAILABLE]: {
          errorType: 'checkout:system',
        },
        [errorCodeConstants.UNKNOWN_GIFT_CARD_SERVICE_RESPONSE_CODE]: {
          errorType: 'checkout:system',
        },
        default: {
          errorType: 'checkout:form',
        },
      },
    },
  },

  [ANALYTICS_PLACE_ORDER_AND_PAY_CLICK]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'placeOrderAndPay',
      type: 'standard',
    },
    detailsToKeep: ['paymentId'],
    replaceByProp: [
      ['checkout.payment.order.paymentId', 'paymentId'],
      ['checkout.payment.order.promoCodeText', 'promoCodeText'],
    ],
  },

  [ANALYTICS_PLACE_ORDER_AND_PAY_CLICK_START]: {
    eventName: 'dataStateChange',
    condition: {
      prop: 'paymentType',
      value: {
        paypal: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'paypal',
          },
        },
        paypalPayLater: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'paypalPayLater',
          },
        },
        gpay: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'gpay',
          },
        },
        applepay: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'applepay',
          },
        },
        applepayExpress: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'applepayExpress',
          },
          replace: [
            ['checkout.applePayExpress', true],
          ],
        },
        posc: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'posc',
          },
        },
        clearpay: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'clearpay',
          },
        },
        klarna: {
          eventDetails: {
            action: 'placeOrderPaymentStart',
            type: 'klarna',
          },
        },
      },
    },
  },

  [ANALYTICS_BATCH_ERROR]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.modal',
      type: 'batchError',
    },
    detailsToKeep: ['products'],
  },

  [ANALYTICS_BATCH_ERROR_ITEMS_REMOVED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.removeItem',
      type: 'batchError',
    },
    detailsToKeep: ['products'],
  },

  [ANALYTICS_OUT_OF_STOCK_MODAL_OPEN]: {
    eventName: 'dataStateChange',
    errorType: 'checkout:system',
    pageName: 'jl:checkout:payment:out of stock',
    eventDetails: {
      action: 'checkout.modal',
      type: 'outofstock',
    },
  },

  [ANALYTICS_AMEND_BASKET_MODAL_OPEN]: {
    eventName: 'dataStateChange',
    errorType: 'checkout:system',
    pageName: 'jl:checkout:reduce quantity',
    eventDetails: {
      action: 'checkout.modal',
      type: 'reducequantity',
    },
  },

  [ANALYTICS_PAYMENT_CARD_DELETE_OPEN]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.modal',
      type: 'deletePaymentCard',
    },
  },

  [ANALYTICS_PARTNER_DISCOUNT_UNAVAILABLE_MODAL_OPEN]: {
    eventName: 'dataStateChange',
    pageName: 'jl:checkout:delivery:partner discount',
  },

  [ANALYTICS_SUBMIT_ORDER_ERROR]: {
    condition: {
      prop: 'error.code',
      value: {
        [errorCodeConstants.INSUFFICIENT_STOCK]: {
          eventName: 'dataStateChange',
          errorType: 'checkout:system',
          pageName: 'jl:checkout:payment:insufficient stock',
          replaceByProp: [
            ['checkout.payment.error.errorCode', 'error.code'],
          ],
          replace: [
            ['checkout.payment.order.trnxnStatus', 'CANCELLED'],
          ],
        },
        [errorCodeConstants.CARD_PAYMENT_NOT_AUTHORISED]: {
          eventName: 'dataStateChange',
          errorType: 'checkout:system',
          replaceByProp: [
            ['checkout.payment.error.errorCode', 'error.code'],
          ],
          replace: [
            ['checkout.payment.order.trnxnStatus', 'FAILED'],
          ],
        },
        [errorCodeConstants.PAYPAL_AUTHORISATION_DECLINED]: {
          eventName: 'dataCheckoutError',
          errorType: 'checkout:payment',
          eventDetails: {
            action: 'paypalAuthorisationDeclined',
          },
        },
        [errorCodeConstants.PAYPAL_AUTHORISATION_DECLINED_WITH_RETRY_PERMITTED]: {
          eventName: 'dataCheckoutError',
          errorType: 'checkout:payment',
          eventDetails: {
            action: 'paypalAuthorisationDeclinedWithRetryPermitted',
          },
        },
        default: {
          eventName: 'dataStateChange',
          errorType: 'checkout:system',
          replaceByProp: [
            ['checkout.payment.error.errorCode', 'error.code'],
          ],
          replace: [
            ['checkout.payment.order.trnxnStatus', 'CANCELLED'],
          ],
        },
      },
    },
  },

  [`${PAYMENT_CARD_DELETE}.SUCCESS`]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.paymentCardDeleted',
    },
  },

  [`${PAYMENT_CARD_DELETE}.FAILED`]: {
    errorType: 'checkout:payment',
    errorMessage: 'We were unable to delete your card',
    eventName: 'dataCheckoutError',
  },

  [ANALYTICS_PREPAID_GIFTCARD_MESSAGE]: {
    errorType: 'checkout:form',
    errorMessage: 'this looks like a pre-paid card',
    eventName: 'dataCheckoutError',
  },

  [`${REMOVE_GIFT_OPTION}.SUCCESS`]: {
    condition: {
      prop: 'item.type',
      value: {
        giftCard: {
          eventDetails: {
            action: 'removeGiftCardPayment',
          },
        },
        giftVoucher: {
          eventDetails: {
            action: 'removeGiftVoucherPayment',
          },
        },
      },
    },
  },

  [ANALYTICS_AUTH0_REGISTRATION_CALLBACK_SUCCESS]: {
    eventDetails: {
      action: 'accountCreated',
    },
    jlAccountSignupOption: 2,
  },

  [ANALYTICS_JOIN_MY_JOHN_LEWIS]: {
    condition: {
      prop: 'status',
      value: {
        [userConstants.MY_JL_JOINED]: {
          eventDetails: {
            action: 'myjlAccountCreated',
          },
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up',
        },
        [userConstants.MY_JL_ACCEPTED]: {
          eventDetails: {
            action: 'queuedMyJLSignUp',
          },
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up',
        },
      },
    },
  },

  [`${JOIN_MY_JOHN_LEWIS}.FAILED`]: {
    errorType: 'checkout:system',
    errorMessage: "Sorry, we've been unable to sign you up to my John Lewis",
  },

  [ANALYTICS_MY_JL_JOIN_MODAL_OPEN]: {
    condition: {
      prop: 'international',
      value: {
        true: {
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up:intl',
        },
        false: {
          pageName: 'jl:checkout:orderreceipt:my john lewis sign up',
        },
      },
    },
  },

  [ANALYTICS_FIND_COLLECTION_POINT_FAILED]: {
    errorType: 'checkout:form',
    errorMessage: 'No collection points available',
  },

  [ANALYTICS_COLLECTION_POINT_DOWN]: {
    errorType: 'checkout:system',
    errorMessage: 'We had a technical issue searching for your location. Please try again.',
  },

  [ANALYTICS_COLLECTION_POINT_CUT_OFF_MODAL_SHOWN]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.modal',
      type: 'deliveryCutOff',
    },
  },

  [ANALYTICS_LEAVE_CHECKOUT_MODAL_SHOWN]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.modal',
      type: 'customerLeavingWarning',
    },
  },

  [`${CARD_PAYMENT}.FAILED`]: {
    errorType: 'checkout:system',
    errorMessage: 'Sorry, your payment was unsuccessful',
  },

  [SET_OCP_3DS_FAIL_ERROR]: {
    errorType: 'checkout:system',
    errorMessage: "Sorry, we've been unable to authenticate your payment",
  },

  [ANALYTICS_FIND_COLLECTION_POINT_WITH_GEO_LOCATION]: {
    eventDetails: {
      action: 'geoLocationSearch',
    },
  },

  [SET_FIND_COLLECTION_POINT_ERROR]: {
    errorType: 'checkout:geolocation',
  },

  [ANALYTICS_LOQATE_TIMEOUT_ENTER_ADDRESS_CLICK]: {
    eventName: 'loqateTimeoutEnterAddressManuallyClicked',
  },

  [ANALYTICS_EXPAND_BASKET_BUTTON_CLICKED]: {
    eventName: 'checkout.basketShowMore',
  },

  [ANALYTICS_MARKETING_OPT_IN]: {
    replaceByProp: [
      ['customer.customerInfo.marketingSelections.optIn.JL', 'JL'],
      ['customer.customerInfo.marketingSelections.optIn.action', 'action'],
    ],
  },

  [ANALYTICS_SAVE_GUEST_ORDER]: {
    replaceByProp: [
      ['customer.customerInfo.saveOrderOption', 'saveOrderOption'],
    ],
  },

  [ANALYTICS_SAVE_GUEST_ORDER_SUCCESS]: {
    eventDetails: {
      type: 'confirmationSaveOrder',
      action: 'checkout.formSubmit',
    },
  },

  [ANALYTICS_PAYMENT_OPTION_SELECTED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.paymentOptionSelected',
    },
    replaceByProp: [
      ['checkout.payment.payOptions', 'payOptionsAnalytics'],
    ],
  },

  [SHOW_EXPRESS_PAYMENTS]: {
    condition: {
      prop: 'shouldShow',
      value: {
        true: {
          replaceByProp: [
            ['checkout.payment.payOptions', 'payOptionsAnalytics'],
          ],
        },
      },
    },
  },

  [ANALYTICS_SHOW_AGE_CHECK_MODAL]: {
    pageName: 'jl:checkout:login:age restriction',
  },

  [`${USER_AGE_CHECK}.FAILED`]: {
    pageName: 'jl:checkout:login:age restriction',
    errorType: 'checkout:agerestriction',
    errorMessage: 'You are not old enough to continue with your order',
  },

  [ANALYTICS_EDIT_BASKET_MODAL_OPEN]: {
    eventDetails: {
      action: 'checkout.modal',
      type: 'basket',
    },
  },

  [BASKET_ITEM_OUT_OF_STOCK]: {
    pageName: 'jl:checkout:out of stock',
    errorType: 'checkout:form',
    errorMessage: 'Sorry, this item is no longer available. Please remove this item to continue.',
    eventDetails: {
      type: 'pageload',
    },
  },

  [ANALYTICS_OPEN_APPLE_PAY_PAYMENT_SHEET]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'openApplePay',
      type: 'apple pay',
    },
    detailsToKeep: ['payment'],
    replace: [
      ['checkout.payment.payOptions.applePay.selected', 1],
    ],
  },

  [ANALYTICS_OPEN_APPLE_PAY_EXPRESS_PAYMENT_SHEET]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'openApplePay',
      type: 'apple pay',
    },
    detailsToKeep: ['payment'],
    replace: [
      ['checkout.payment.payOptions.applePayExpress.selected', 1],
    ],
  },

  [SHOW_POS_CREDIT_BASKET_AMEND_MODAL]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.modal',
      type: 'poscBasket',
    },
  },

  [ANALYTICS_POS_CREDIT_INELIGIBLE_ITEMS_REMOVED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.removeItem',
    },
    detailsToKeep: ['products'],
  },

  [ANALYTICS_VOUCHER_UNDERSPEND_MODAL_OPEN]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'checkout.modal',
      type: 'voucherOverspend',
    },
  },

  [ANALYTICS_VOUCHER_UNDERSPEND_REMOVED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'removeGiftVoucherPayment',
      type: 'voucherOverspend',
    },
  },

  [ANALYTICS_SHOW_COLLECTION_HELP]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.impression',
      type: 'delivery',
      subType: 'collection unavailable message',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [ANALYTICS_SHOW_COLLECTION_HELP_REMOVE]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'delivery basket modal',
      subType: 'remove collection unavailable items',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [ANALYTICS_SHOW_PERSONALISATION_HELP]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.impression',
      type: 'checkout',
      subType: 'personalised product message',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [ANALYTICS_SHOW_PERSONALISATION_CONFIRMATION]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'order receipt',
      subType: 'navigate to personlised product pdp',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [ANALYTICS_JOIN_MY_JOHN_LEWIS_CANCELLED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'account created - myjl - go back to your order',
      additionalInfo: {},
      properties: {},
    },
  },

  [ANALYTICS_CREATE_ACCOUNT_CANCELLED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'account details - go back to your order',
      additionalInfo: {},
      properties: {},
    },
  },

  [PRINT_ORDER_BUTTON_CLICKED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout:orderreceipt',
      subType: 'print order',
      additionalInfo: {},
      product: {},
    },
  },

  [ANALYTICS_GIFT_OPTIONS_CHANGE_TO_PREPAID]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout:payment:use gift card',
      subType: 'enter your details in the credit card section',
      additionalInfo: {},
      product: {},
    },
  },

  [ANALYTICS_ADD_NEW_CARD_CLICK]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout:payment',
      subType: 'add credit, debit or prepaid card',
      additionalInfo: {},
      product: {},
    },
  },

  [ANALYTICS_SAVED_CARD_DETAILS_TOGGLED]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout:payment',
      subType: '${subType}',
      additionalInfo: {},
      product: {},
      stringVarsToReplace: ['subType'],
    },
  },

  [APPLE_PAY_PAYMENT_CANCEL]: {
    errorType: 'checkout:applepay:cancelled',
    errorMessage: 'User cancelled applepay session',
    replaceByProp: [['checkout.applePayExpress', false]],
  },

  [APPLE_PAY_PAYMENT_FAILURE]: {
    errorType: 'checkout:applepay:failed',
    errorMessage: "Sorry, we've been unable to authenticate your payment",
    replaceByProp: [['checkout.applePayExpress', false]],
  },

  [APPLE_PAY_SELECTED]: {
    replace: [
      ['checkout.payment.payOptions.applePay.enabled', 1],
      ['checkout.payment.payOptions.applePay.default', 1],
      ['checkout.payment.payOptions.cc_saved.default', 0],
    ],
  },

  [SET_CAN_MAKE_APPLE_PAY_PAYMENTS]: {
    condition: {
      prop: 'route.pathname',
      value: {
        [`${routeConstants.PAYMENT}`]: {
          replace: [
            ['checkout.payment.payOptions.applePay.enabled', 1],
          ],
        },
      },
    },
  },

  [GOOGLE_PAY_ENABLED]: {
    replace: [
      ['checkout.payment.payOptions.googlePay.enabled', 1],
      ['checkout.payment.payOptions.cc_saved.default', 0],
    ],
  },

  [CLEARPAY_ENABLED_STATUS]: {
    condition: {
      prop: 'enabled',
      value: {
        true: {
          replace: [
            ['checkout.payment.payOptions.clearpay.enabled', 1],
          ],
        },
        false: {
          replace: [
            ['checkout.payment.payOptions.clearpay.enabled', 0],
          ],
        },
      },
    },
  },

  [KLARNA_ENABLED_STATUS]: {
    condition: {
      prop: 'enabled',
      value: {
        true: {
          replace: [
            ['checkout.payment.payOptions.klarna.enabled', 1],
          ],
        },
        false: {
          replace: [
            ['checkout.payment.payOptions.klarna.enabled', 0],
          ],
        },
      },
    },
  },

  [GOOGLE_PAY_DEFAULT]: {
    replace: [
      ['checkout.payment.payOptions.googlePay.enabled', 1],
      ['checkout.payment.payOptions.googlePay.default', 1],
      ['checkout.payment.payOptions.cc_saved.default', 0],
    ],
  },

  [SHOW_POS_CREDIT_GET_READY_MODAL]: {
    eventDetails: {
      action: 'checkout.modal',
      type: 'poscEligibility',
    },
  },

  [`${SET_CHECK_ELIGIBILITY_PROCESSING}.TRUE`]: {
    eventDetails: {
      action: 'Checkout placeOrderAndPay',
    },
  },

  [SAVED_CARD_SELECTED]: {
    replace: [
      ['checkout.payment.payOptions.cc_saved.default', 1],
    ],
  },
  [OCP_SUBMIT_3DS_METHOD_ERROR]: {
    errorType: 'checkout:system',
    errorMessage: 'Sorry, your payment was unsuccessful',
  },
  [TRANSACTION_STATUS_UPDATE]: {
    condition: {
      prop: 'transactionStatus',
      value: {
        FAILED: {
          replaceByProp: [
            ['checkout.payment.error.errorCode', 'errorCode'],
            ['checkout.payment.order.trnxnStatus', 'transactionStatus'],
            ['checkout.payment.order.authenticationStatus', 'authenticationStatus'],
          ],
        },
        INITIAL: {
          replace: [
            ['checkout.payment.order.trnxnStatus', 'DEFERRED_AUTHORISATION'],
            ['checkout.payment.error', undefined],
            ['error', ''],
          ],
        },
        default: {
          replaceByProp: [
            ['checkout.payment.order.trnxnStatus', 'transactionStatus'],
            ['checkout.payment.order.authenticationStatus', 'authenticationStatus'],
          ],
          replace: [
            ['checkout.payment.error', undefined],
            ['error', ''],
          ],
        },
      },
    },
  },
  [BIN_NUMBER_UPDATE]: {
    replaceByProp: [
      ['checkout.payment.order.bn', 'encryptedBinNumber'],
    ],
  },
  [ANALYTICS_UPDATE_PAGEINFO]: {
    replaceByProp: [
      ['page.pageInfo.reportSuite', 'reportSuite'],
      ['page.pageInfo.site', 'site'],
    ],
  },
  [CHANGE_COLLECTION_POINT]: {
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'change shop',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [`${APPLY_REWARDS}.FAILED`]: {
    errorType: 'checkout:rewards',
    errorMessage: "Sorry, we couldn't apply the reward",
  },

  [`${REMOVE_REWARDS}.FAILED`]: {
    errorType: 'checkout:rewards',
    errorMessage: "Sorry, we couldn't remove the reward",
  },

  [ANALYTICS_REWARDS_TOOLTIP]: {
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'rewards toggle tooltip',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  [ANALYTICS_REWARDS_TOGGLE]: {
    condition: {
      prop: 'isChecked',
      value: {
        true: {
          eventDetails: {
            action: 'global.interaction',
            type: 'checkout',
            subType: 'rewards toggle on',
            additionalInfo: {},
            properties: {},
            products: [],
          },
        },
        default: {
          eventDetails: {
            action: 'global.interaction',
            type: 'checkout',
            subType: 'rewards toggle off',
            additionalInfo: {},
            properties: {},
            products: [],
          },
        },
      },
    },
  },

  // Age Verification
  [AGE_VERIFICATION_SKIP_RESTRICTED]: {
    eventDetails: {
      action: 'global.interaction',
      type: 'verification',
      subType: 'continue without restricted items',
      additionalInfo: { bladedItem: 'mixed' },
      properties: {},
      products: [],
    },
  },
  [AGE_VERIFICATION_VERIFY_AGE_CLICK]: {
    condition: {
      prop: 'bladedItem',
      value: {
        only: {
          eventDetails: {
            action: 'global.interaction',
            type: 'verification',
            subType: 'verify your age',
            additionalInfo: { bladedItem: 'only' },
            properties: {},
            products: [],
          },
        },
        default: {
          eventDetails: {
            action: 'global.interaction',
            type: 'verification',
            subType: 'verify your age',
            additionalInfo: { bladedItem: 'mixed' },
            properties: {},
            products: [],
          },
        },
      },
    },
  },
  [AGE_VERIFICATION_FAILED_RETURN_TO_BASKET_CLICK]: {
    condition: {
      prop: 'bladedItem',
      value: {
        only: {
          eventDetails: {
            action: 'global.interaction',
            type: 'verification',
            subType: 'failed - return to basket',
            additionalInfo: { bladedItem: 'only' },
            properties: {},
            products: [],
          },
        },
        default: {
          eventDetails: {
            action: 'global.interaction',
            type: 'verification',
            subType: 'failed - return to basket',
            additionalInfo: { bladedItem: 'mixed' },
            properties: {},
            products: [],
          },
        },
      },
    },
  },
  [AGE_VERIFICATION_SUCCESS]: {
    condition: {
      prop: 'bladedItem',
      value: {
        only: {
          replace: [
            ['checkout.additionalDetail.ageverification', 'success'],
            ['checkout.additionalDetail.bladedItem', 'only'],
          ],
        },
        default: {
          replace: [
            ['checkout.additionalDetail.ageverification', 'success'],
            ['checkout.additionalDetail.bladedItem', 'mixed'],
          ],
        },
      },
    },
  },
  [AGE_VERIFICATION_FAILED]: {
    replace: [
      ['checkout.additionalDetail.ageverification', 'failed'],
    ],
  },
  [AGE_VERIFICATION_BLADED_ARTICLE]: {
    condition: {
      prop: 'bladedItem',
      value: {
        only: {
          replace: [
            ['checkout.additionalDetail.bladedItem', 'only'],
          ],
        },
        default: {
          replace: [
            ['checkout.additionalDetail.bladedItem', 'mixed'],
          ],
        },
      },
    },
  },
  [THRESHOLD_BAR_KEEP_SHOPPING_CLICK]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'threshold bar',
      additionalInfo: { navType: '${location}' },
      properties: {},
      products: [],
      stringVarsToReplace: ['location'],
    },
  },
  [THRESHOLD_BAR_SHOWN]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'keep shopping',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },
  [THRESHOLD_BAR_SHOWN_ON_QUANTITY_EDIT]: {
    eventName: 'dataStateChange',
    eventDetails: {
      action: 'global.interaction',
      type: 'checkout',
      subType: 'edit quantity - threshold bar',
      additionalInfo: {},
      properties: {},
      products: [],
    },
  },

  // Price Promise
  [PRICE_PROMISE_GLOBAL_INTERACTION]: {
    condition: {
      prop: 'location',
      value: {
        basket: {
          eventDetails: {
            action: 'global.interaction',
            type: 'basket',
            subType: 'price promise',
            additionalInfo: {},
            properties: {},
            products: [],
          },
        },
        default: {
          eventDetails: {
            action: 'global.interaction',
            type: 'checkout',
            subType: 'price promise',
            additionalInfo: {},
            properties: {},
            products: [],
          },
        },
      },
    },
  },
};
