// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import isEqual from 'lodash/isEqual';
import flatMap from 'lodash/flatMap';
// utils
import getOrderDetails from '../../../utils/helpers/getOrderDetails';
import getUnavailableItems from '../../../utils/orderform/getUnavailableItems';
import getAppliedPayments from '../../../utils/orderform/getAppliedPayments';
import { isZeroAmount } from '../../../utils';
import { isCollectPlusRelatedType } from '../../../utils/collection/getCollectPlusRelatedStores';
//
import {
  APPLY_REWARDS,
  APPLY_GIFT_CARD,
  APPLY_GIFT_VOUCHER,
  APPLY_PROMO_CODE,
  BASKET_ITEM_OUT_OF_STOCK,
  GET_DELIVERY_PAGE,
  GET_ITEMS,
  GET_ORDER_CONFIRMATION_PAGE,
  GET_PAYMENT_PAGE,
  HANDLE_EDIT_BASKET_QUANTITY_ERROR,
  HIDE_ITEM_REMOVE_OVERLAY,
  HIDE_POS_CREDIT_BASKET_AMEND_MODAL,
  INIT_PAYMENT_PAGE,
  LOGIN_RESET,
  POST_DELIVERY_PAGE,
  PUT_ITEM_QUANTITY,
  PUT_ITEMS_QUANTITIES,
  REBATCH_ORDER,
  REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET,
  REMOVE_BASKET_ITEMS,
  REMOVE_GIFT_OPTION,
  REMOVE_PROMO_CODE,
  ROUTER_LOCATION_CHANGE,
  SET_EDIT_BASKET_BASE_ROUTE,
  SET_SELECTED_DELIVERY_CHOICE_ID,
  SHOW_EMPTY_BASKET_NOTIFICATION,
  SHOW_ITEM_REMOVE_OVERLAY,
  SHOW_POS_CREDIT_BASKET_AMEND_MODAL,
  SUBMIT_ORDER,
  USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS,
  USE_DIFFERENT_BILLING_ADDRESS,
  VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE,
  REMOVE_REWARDS,
  GET_PAYMENTS,
  CLOSE_EDIT_BASKET_MODAL,
} from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import deliveryConstants from '../../../constants/deliveryConstants';
import routeConstants from '../../../constants/routeConstants';
import { brokenRules, requiredActionTypes } from '../../../constants/posCreditConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';

export const checkGvfSlotGreaterThanTwoHours = (delivery = {}, deliveries = []) => {
  const selectedMethodId = delivery.fulfilment.id;
  const targetDelivery = deliveries?.find(deliveryRecord => deliveryRecord?.id === delivery.id);
  const selectedMethod = targetDelivery?.methods.find(m => m?.id === selectedMethodId);
  return !!selectedMethod?.fulfilmentOffers?.showDeliveryWindowMessage;
};

export const getOutOfStockStockItems = orderForm => get(orderForm, 'items', [])
  .filter(item => Number(item?.availability?.stockLevel) === 0).map(item => item);

export const getLimitedStockItems = orderForm => get(orderForm, 'items', [])
  .filter(item => Number(item?.availability?.stockLevel) > 0 && Number(item?.availability?.stockLevel) < item.quantity)
  .map(item => item);

export const parseDeliveries = (orderDeliveries = [], deliveryDeliveries = []) => (
  orderDeliveries.map((delivery = {}) => {
    const type = delivery.type;
    const giftMessage = get(delivery, 'fulfilment.giftMessageLines', []);
    const giftMessageLines = giftMessage[0] === 'This is a gift receipt' ? [] : giftMessage;

    if (type === deliveryConstants.GREEN_VAN_FLEET) {
      const isGvfSlotGreaterThanTwoHours = checkGvfSlotGreaterThanTwoHours(delivery, deliveryDeliveries);
      return {
        ...delivery,
        isGvfSlotGreaterThanTwoHours,
      };
    }
    return {
      ...delivery,
      isGiftReceiptDelivery: !isEmpty(giftMessage),
      giftMessageLines,
    };
  })
);

export function setNotAvailableForPartnerDiscountItems(state, orderForm) {
  const itemsNotAvailableForPartnerDiscount = get(orderForm, 'itemsNotAvailableForPartnerDiscount');
  const items = get(orderForm, 'items', []);

  if (!isEmpty(itemsNotAvailableForPartnerDiscount)) {
    return items.filter(item => itemsNotAvailableForPartnerDiscount.includes(item.id));
  }

  return state.notAvailableForPartnerDiscountItems || undefined;
}

function getItemQuantities(items = []) {
  if (!items.length) {
    return {};
  }

  return items.reduce((acc, item) => {
    const childItemQuantities = getItemQuantities(item.childItems);
    return {
      ...acc,
      [item.id]: item.quantity,
      ...childItemQuantities,
    };
  }, {});
}

function haveItemQuantitiesChanged(startItemQuantities, newItems) {
  if (!startItemQuantities) {
    return false;
  }
  const newItemQuantities = getItemQuantities(newItems);
  return !isEqual(startItemQuantities, newItemQuantities);
}

export const isInternational = (state) => {
  const userAddress = get(state, 'createAccount.userAddress');

  if (isEmpty(userAddress)) {
    return get(state, 'confirmation.order.deliveryAddress.address.countryCode') !== 'GB';
  }
  return get(userAddress, 'address.countryCode') !== 'GB';
};

const hasPosCreditSecondaryPaymentRulesBroken = (returnedBrokenRules) => {
  const secondaryPaymentRules = [
    brokenRules.GIFT_CARD_APPLIED,
    brokenRules.GIFT_VOUCHER_APPLIED,
  ];
  return secondaryPaymentRules.some(rule => returnedBrokenRules.includes(rule));
};

export const INITIAL_STATE = {
  getPaymentPageComplete: false,
  amounts: {
    deliveryCharge: '',
    grandTotal: '',
    outstandingBalance: '',
    paymentTotals: {
      creditCard: '',
      giftCard: '',
      giftVoucher: '',
      payPal: '',
    },
  },
  deliveries: [],
  deliveryAddress: {},
  billingAddress: {},
  useDeliveryAddressAsBillingAddress: true,
  items: [],
  payments: [],
  promoCodeApplied: undefined,
  applyPromoCodeLoading: false,
  ocpIntegration: {
    baseUrl: '',
  },
  isGiftReceiptOrder: false,
  giftMessageLines: [],
  showBusinessAccountOcpOrderError: false,
  outOfStockItems: [],
  notAvailableForPartnerDiscountItems: [],
  putItemQuantityApiCallActive: false,
  orderNeedsToBeRebatched: false,
  rebatchOrderApiCallActive: false,
  getItemsApiCallActive: false,
  removeBasketItemsApiCallActive: false,
  amendItemsFailed: false,
  amendQuantitiesApiCallActive: false,
  removeBasketItemsFailed: false,
  editBasketRemoveItemApiCallActiveIds: [],
  editBasketItemRemoveOverlayIds: [],
  editBasketItemOutOfStockOverlayIds: [],
  editBasketItemUpdateActiveIds: [],
  editBasketItemsLastRemovedIds: [],
  editBasketStartItemQuantities: undefined,
  orderDetails: {},
  submitOrderFailed: false,
  posCreditSecondaryRulesBroken: false,
  posCreditProviderUnavailable: false,
  posCreditIneligibleItems: [],
  posCreditIneligibleItemIds: [],
  isPosCreditSubmissionError: false,
  basketAmendCreditTypeGroup: undefined,
  showPOSCreditBasketAmendModal: false,
  silentlyAppliedPromoCode: false,
  tradeInQuotes: [],
  shouldApplyRewards: true,
};

export default function orderFormReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOGIN_RESET:
      return INITIAL_STATE;

    case `${GET_PAYMENT_PAGE}.LOADING`:
    case `${INIT_PAYMENT_PAGE}.LOADING`:
      return {
        ...state,
        getPaymentPageComplete: false,
      };

    case `${GET_PAYMENT_PAGE}.FAILED`:
    case `${INIT_PAYMENT_PAGE}.FAILED`:
      return {
        ...state,
        getPaymentPageComplete: false,
      };

    case `${POST_DELIVERY_PAGE}.SUCCESS`:
    case `${GET_DELIVERY_PAGE}.SUCCESS`: {
      const orderForm = get(action, 'result.orderForm', {});

      return {
        ...state,
        amounts: orderForm.amounts || state.amounts || {},
        items: orderForm.items || state.items || [],
        notAvailableForPartnerDiscountItems: setNotAvailableForPartnerDiscountItems(state, orderForm),
        outOfStockItems: getOutOfStockStockItems(orderForm),
        limitedStockItems: getLimitedStockItems(orderForm),
        tradeInQuotes: orderForm.tradeInQuotes || [],
        promoCodeApplied: get(action, 'result.orderForm.appliedPromotion'),
        unavailableItemInfo: getUnavailableItems(orderForm.items),
        ocpIntegration: get(action, 'result.ocpIntegration'),
      };
    }

    case `${GET_PAYMENT_PAGE}.SUCCESS`:
    case `${INIT_PAYMENT_PAGE}.SUCCESS`: {
      const orderForm = get(action, 'result.orderForm', {});
      const creditPaymentDetails = action?.result?.creditPaymentDetails ?? {};

      const posCreditBrokenRules = creditPaymentDetails?.brokenRules ?? [];
      const posCreditSecondaryRulesBroken = hasPosCreditSecondaryPaymentRulesBroken(posCreditBrokenRules);
      const posCreditProviderUnavailable = posCreditBrokenRules?.includes(brokenRules.CREDIT_PROVIDER_UNAVAILABLE);
      const posCreditBasketLimitExceeded = posCreditBrokenRules?.includes(brokenRules.MAX_ORDER_LINES_EXCEEDED);
      const posCreditCollectionPointChangeRequired = posCreditBrokenRules?.includes(
        brokenRules.LOCAL_COLLECTION_NETWORK_NOT_ALLOWED,
      );
      const posCreditBasketExcess = creditPaymentDetails?.basketExcess;

      const paymentWalletAddress = get(action, 'result.customer.paymentWallet[0].address', null);

      const isClickCollectOrder = !!get(orderForm, 'deliveries[0].fulfilment.collectionInfo');
      const deliveryAddress = orderForm.deliveryAddress || {};
      if (isClickCollectOrder && action.selectedCollectionPoint) {
        const ownerId = orderForm.deliveries[0].fulfilment.collectionInfo.ownerId;
        if (isCollectPlusRelatedType(ownerId)) {
          deliveryAddress.address.addressLine1 = action.selectedCollectionPoint.storeName;
        } else {
          deliveryAddress.address.addressLine2 = action.selectedCollectionPoint.storeName;
        }
      }

      const orderCoveredTotally = isZeroAmount(get(action, 'result.orderForm.amounts.outstandingBalance'));

      let useDeliveryAddressAsBillingAddress;
      if (isClickCollectOrder) {
        useDeliveryAddressAsBillingAddress = false;
      } else if (orderCoveredTotally) {
        useDeliveryAddressAsBillingAddress = true;
      } else {
        useDeliveryAddressAsBillingAddress = state.useDeliveryAddressAsBillingAddress;
      }

      const deliveries = parseDeliveries(orderForm.deliveries, action.deliveries) || [];

      const removedPayments = state.payments?.filter(payment => payment.isRemoved);
      const payments = !isEmpty(removedPayments) ?
        orderForm.payments.concat(removedPayments) : get(orderForm, 'payments', []);

      const removedPromoCode = get(state.promoCodeApplied, 'isRemoved', false) ? state.promoCodeApplied : undefined;

      return {
        ...state,
        id: orderForm.id,
        omsOrderId: orderForm.omsOrderId,
        orderDetails: getOrderDetails({
          deliveries,
          deliveryAddress,
          isClickCollectOrder,
        }),
        getPaymentPageComplete: true,
        amounts: orderForm.amounts || {},
        deliveries,
        items: orderForm.items || [],
        deliveryAddress,
        promoCodeApplied: get(action, 'result.orderForm.appliedPromotion', removedPromoCode),
        useDeliveryAddressAsBillingAddress: paymentWalletAddress ? true : useDeliveryAddressAsBillingAddress,
        isClickCollectOrder,
        ocpIntegration: get(action, 'result.ocpIntegration'),
        isGiftReceiptOrder: deliveries.some(delivery => delivery.isGiftReceiptDelivery) ?? false,
        isGvfSlotGreaterThanTwoHours: !!deliveries.find(deliveryRecord => deliveryRecord.isGvfSlotGreaterThanTwoHours),
        outOfStockItems: getOutOfStockStockItems(orderForm),
        limitedStockItems: getLimitedStockItems(orderForm),
        isPosCreditSubmissionError: get(action, 'eventDetails.posCreditSubmitOrderError', false),
        orderNeedsToBeRebatched: false,
        submitOrderFailed: false,
        posCreditSecondaryRulesBroken,
        posCreditProviderUnavailable,
        posCreditBasketLimitExceeded,
        posCreditCollectionPointChangeRequired,
        posCreditBasketExcess,
        payments: payments.map((payment) => {
          const appliedPayment = orderForm.payments?.filter(appliedPayment => payment.id === appliedPayment.id)[0];
          const previousStateEntry = state.payments?.find(paymentRecord => paymentRecord.id === payment.id);

          const isGiftCard = get(previousStateEntry, 'type', '') === paymentTypeConstants.GIFT_CARD;
          const isGiftVoucher = get(previousStateEntry, 'type', '') === paymentTypeConstants.GIFT_VOUCHER;

          return appliedPayment ? {
            ...appliedPayment,
            details: {
              ...appliedPayment.details,
              ...(isGiftCard && {
                cardNumber: previousStateEntry.details.cardNumber,
                pin: previousStateEntry.details.pin,
              }),
              ...(isGiftVoucher && {
                securityCode: previousStateEntry.details.securityCode,
                serialNumber: previousStateEntry.details.serialNumber,
              }),
            },
          } : payment;
        }),
        unavailableItemInfo: getUnavailableItems(orderForm.items),
      };
    }

    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      return {
        ...state,
        billingAddress: get(action, 'result.order.billingAddress', {}),
      };
    }

    case `${SUBMIT_ORDER}.FAILED`: {
      return {
        ...state,
        submitOrderFailed: true,
      };
    }
    case USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS:
      return {
        ...state,
        useDeliveryAddressAsBillingAddress: true,
      };

    case USE_DIFFERENT_BILLING_ADDRESS:
      return {
        ...state,
        useDeliveryAddressAsBillingAddress: false,
      };

    case `${APPLY_GIFT_CARD}.SUCCESS`:
    case `${APPLY_GIFT_VOUCHER}.SUCCESS`:
    case `${GET_PAYMENTS}.SUCCESS`: {
      const returnedPayments = get(action, 'result.payments', []);

      const posCreditBrokenRules = get(action, 'result.creditPaymentDetails.brokenRules', []);
      const posCreditSecondaryRulesBroken = hasPosCreditSecondaryPaymentRulesBroken(posCreditBrokenRules);

      let payments;

      if (state.payments && state.payments.length) {
        const paymentsCurrentExcluded = state.payments.filter((payment) => {
          if (action.type === `${APPLY_GIFT_CARD}.SUCCESS`) {
            return !(action.cardNumber === get(payment, 'details.cardNumber') &&
              action.pin === get(payment, 'details.pin'));
          }

          if (action.type === `${APPLY_GIFT_VOUCHER}.SUCCESS`) {
            return !(action.securityCode === get(payment, 'details.securityCode') &&
              action.serialNumber === get(payment, 'details.serialNumber'));
          }

          return false;
        });

        const removedPayments = paymentsCurrentExcluded?.filter(payment => payment.isRemoved);

        payments = removedPayments ? returnedPayments.concat(removedPayments) : returnedPayments;

      } else {
        payments = returnedPayments;
      }

      const mappedPayments = payments.map((payment) => {
        if (payment.type === paymentTypeConstants.GIFT_CARD) {
          const activePayment = get(payment, 'details.cardNumber', '') === action.cardNumber;
          if (activePayment) {
            return {
              ...payment,
              details: {
                ...payment.details,
                pin: action.pin,
                cardNumber: action.cardNumber,
              },
            };
          }

          const statePayment = !payment.id ?
            undefined : state.payments?.find(paymentRecord => paymentRecord?.id === payment?.id);

          if (statePayment?.amount !== payment.amount) {
            // handles scenario in https://www.jlpit.com/jira/browse/MARV-7888 where gift voucher application
            // supercedes a previously applied gift card
            return {
              ...payment,
              details: {
                ...payment.details,
                pin: statePayment?.details.pin || action.pin,
                cardNumber: statePayment?.details.cardNumber || action.cardNumber,
              },
            };
          }

          return statePayment || {
            ...payment,
            details: {
              ...payment.details,
              pin: action.pin || payment.details.pin,
              cardNumber: action.cardNumber || payment.details.cardNumber,
            },
          };
        }

        if (payment.type === paymentTypeConstants.GIFT_VOUCHER) {
          const activePayment = get(payment, 'details.serialNumber', '') === action.serialNumber;
          if (activePayment) {
            return {
              ...payment,
              details: {
                ...payment.details,
                securityCode: action.securityCode,
                serialNumber: action.serialNumber,
              },
            };
          }
          const statePayment = !payment.id ?
            undefined : state.payments?.find(paymentRecord => paymentRecord?.id === payment.id);
          return statePayment || {
            ...payment,
            details: {
              ...payment.details,
              securityCode: action.securityCode || payment.details.securityCode,
              serialNumber: action.serialNumber || payment.details.serialNumber,
            },
          };
        }

        return payment;
      });

      const orderCoveredTotally = isZeroAmount(get(action, 'result.amounts.outstandingBalance'));

      const appliedVouchers = get(action, 'result.payments', []).filter(payment => payment.type === 'giftVoucher');
      const voucherUnderspend = appliedVouchers?.find(payment => payment.details.remainingBalance !== '£0.00');

      return {
        ...state,
        amounts: get(action, 'result.amounts', {}),
        useDeliveryAddressAsBillingAddress:
          orderCoveredTotally && !state.isClickCollectOrder ? true : state.useDeliveryAddressAsBillingAddress,
        payments: mappedPayments,
        posCreditSecondaryRulesBroken,
        ...voucherUnderspend && { voucherUnderspend },
      };
    }

    case VOUCHER_UNDERSPEND_WARNING_MODAL_CLOSE:
      return {
        ...state,
        voucherUnderspendModalClose: true,
      };

    case `${APPLY_REWARDS}.LOADING`: {
      return {
        ...state,
        applyRewardsApiCallActive: true,
      };
    }

    case `${APPLY_REWARDS}.FAILED`: {
      return {
        ...state,
        shouldApplyRewards: false,
        applyRewardsFailed: true,
        applyRewardsApiCallActive: false,
      };
    }

    case `${APPLY_REWARDS}.SUCCESS`: {
      const amounts = get(action, 'result.amounts') || get(action, 'result.orderForm.amounts');
      const rewardApplicable = !!amounts.rewardsDiscountTotal;
      const rewards = get(action, 'result.orderForm.rewards');
      const removedPromoCode = state.promoCodeApplied ? {
        ...state.promoCodeApplied,
        isRemoved: true,
      } : undefined;

      return {
        ...state,
        amounts,
        customerRemovedRewards: (state.customerRemovedRewards && action.isCustomerAction) ? false :
          state.customerRemovedRewards,
        rewardApplicable,
        rewards,
        shouldApplyRewards: false,
        applyRewardsFailed: false,
        ...removedPromoCode && { promoCodeApplied: removedPromoCode },
        shouldHideRewardsValue: false,
        applyRewardsApiCallActive: false,
      };
    }

    case `${REMOVE_REWARDS}.LOADING`: {
      return {
        ...state,
        removeRewardsApiCallActive: true,
      };
    }

    case `${REMOVE_REWARDS}.FAILED`: {
      return {
        ...state,
        removeRewardsFailed: true,
        removeRewardsApiCallActive: false,
      };
    }

    case `${REMOVE_REWARDS}.SUCCESS`: {
      return {
        ...state,
        amounts: get(action, 'result.orderForm.amounts'),
        shouldApplyRewards: false,
        customerRemovedRewards: true,
        removeRewardsFailed: false,
        removeRewardsApiCallActive: false,
      };
    }

    case `${APPLY_PROMO_CODE}.LOADING`:
      return {
        ...state,
        applyPromoCodeLoading: true,
      };

    case `${APPLY_PROMO_CODE}.SUCCESS`: {
      if (!action.result.amounts) {
        return {
          ...state,
          silentlyAppliedPromoCode: action.silent,
          promoCodeApplied: action.result,
        };
      }

      const orderCoveredTotally = isZeroAmount(get(action, 'result.amounts.outstandingBalance'));
      const returnedPayments = get(action, 'result.payments');

      return {
        ...state,
        silentlyAppliedPromoCode: false,
        applyPromoCodeLoading: false,
        removePromoCodeFailed: false,
        applyRewardsFailed: false,
        removeRewardsFailed: false,
        promoCodeApplied: get(action, 'result.promotion'),
        amounts: get(action, 'result.amounts'),
        useDeliveryAddressAsBillingAddress:
          orderCoveredTotally && !state.isClickCollectOrder ? true : state.useDeliveryAddressAsBillingAddress,
        payments: getAppliedPayments(state.payments, returnedPayments),
        applyPromoCodeFailed: false,
        applyPromoCodeFailedCode: undefined,
      };
    }

    case `${APPLY_PROMO_CODE}.FAILED`:
      return {
        ...state,
        applyPromoCodeLoading: false,
        applyPromoCodeFailed: true,
        applyPromoCodeFailedCode: action.code,
        removePromoCodeFailed: false,
      };

    case `${REMOVE_PROMO_CODE}.LOADING`:
      return {
        ...state,
        isLoading: true,
        silentlyAppliedPromoCode: false,
        promoCodeApplied: {
          ...state.promoCodeApplied,
          isLoading: true,
        },
      };

    case `${REMOVE_PROMO_CODE}.SUCCESS`: {
      const returnedPayments = get(action, 'result.payments');

      return {
        ...state,
        isLoading: false,
        promoCodeApplied: {
          ...state.promoCodeApplied,
          isLoading: false,
          isRemoved: true,
        },
        amounts: get(action, 'result.amounts'),
        removePromoCodeFailed: false,
        payments: getAppliedPayments(state.payments, returnedPayments),
      };
    }

    case `${REMOVE_PROMO_CODE}.FAILED`:
      return {
        ...state,
        removePromoCodeFailed: ![errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE,
          errorCodeConstants.INCOMPLETE_DELIVERY_DETAILS].includes(get(action, 'error.code')),
        promoCodeApplied: {
          ...state.promoCodeApplied,
          isLoading: false,
        },
      };

    case `${REMOVE_GIFT_OPTION}.LOADING`: {
      const { item } = action;
      return {
        ...state,
        removeGiftOptionLoading: true,
        payments: state.payments.map((payment) => {
          if (item.id === payment.id) {
            return {
              ...payment,
              isLoading: true,
            };
          }
          return payment;
        }),
      };
    }

    case `${REMOVE_GIFT_OPTION}.FAILED`: {
      const { item } = action;
      return {
        ...state,
        removeGiftOptionLoading: false,
        payments: state.payments.map((payment) => {
          if (item.id === payment.id) {
            return {
              ...payment,
              isLoading: false,
            };
          }
          return payment;
        }),
      };
    }

    case `${REMOVE_GIFT_OPTION}.SUCCESS`: {
      const appliedPayments = get(action, 'result.payments', []);

      const posCreditBrokenRules = get(action, 'result.creditPaymentDetails.brokenRules', []);
      const posCreditSecondaryRulesBroken = hasPosCreditSecondaryPaymentRulesBroken(posCreditBrokenRules);
      return {
        ...state,
        removeGiftOptionLoading: false,
        amounts: get(action, 'result.amounts'),
        payments: state.payments.map((payment) => {
          const appliedPayment = appliedPayments?.filter(appliedPayment => payment.id === appliedPayment.id)[0];
          const previousStateEntry = state.payments?.find(paymentRecord => paymentRecord.id === payment.id);

          const isGiftCard = get(previousStateEntry, 'type', '') === paymentTypeConstants.GIFT_CARD;
          const isGiftVoucher = get(previousStateEntry, 'type', '') === paymentTypeConstants.GIFT_VOUCHER;

          return appliedPayment ? {
            ...appliedPayment,
            details: {
              ...appliedPayment.details,
              ...(isGiftCard && {
                pin: previousStateEntry.details.pin,
                cardNumber: previousStateEntry.details.cardNumber,
              }),
              ...(isGiftVoucher && {
                securityCode: previousStateEntry.details.securityCode,
                serialNumber: previousStateEntry.details.serialNumber,
              }),
            },
          } : {
            ...payment,
            isRemoved: true,
            isLoading: false,
            removalContext: payment.removalContext || action.removalContext,
            details: {
              ...payment.details,
              ...(isGiftCard && {
                pin: previousStateEntry.details.pin,
                cardNumber: previousStateEntry.details.cardNumber,
              }),
              ...(isGiftVoucher && {
                securityCode: previousStateEntry.details.securityCode,
                serialNumber: previousStateEntry.details.serialNumber,
              }),
            },
          };
        }),
        posCreditSecondaryRulesBroken,
        voucherUnderspend: null,
      };
    }

    case SET_SELECTED_DELIVERY_CHOICE_ID: {
      if (action.resetUseDeliveryAddressAsBillingAddress) {
        return {
          ...state,
          useDeliveryAddressAsBillingAddress: true,
        };
      }
      return state;
    }

    case `${PUT_ITEM_QUANTITY}.LOADING`: {
      const { itemId } = action;

      return {
        ...state,
        putItemQuantityApiCallActive: true,
        editBasketItemUpdateActiveIds: union(state.editBasketItemUpdateActiveIds, [itemId]),
        errorMessage: undefined,
        errorMessageId: undefined,
        errorItemId: undefined,
      };
    }

    case `${PUT_ITEMS_QUANTITIES}.LOADING`:
      return {
        ...state,
        amendQuantitiesApiCallActive: true,
      };

    case `${PUT_ITEM_QUANTITY}.SUCCESS`: {
      const { itemId } = action;
      const orderForm = get(action, 'result.orderForm', {});
      const amounts = orderForm.amounts;
      const items = orderForm.items;

      return {
        ...state,
        amounts,
        items,
        putItemQuantityApiCallActive: false,
        orderNeedsToBeRebatched: true,
        editBasketItemUpdateActiveIds: state.editBasketItemUpdateActiveIds.filter(id => id !== itemId),
        outOfStockItems: getOutOfStockStockItems(orderForm),
        limitedStockItems: getLimitedStockItems(orderForm),
        promoCodeApplied: get(action, 'result.orderForm.appliedPromotion'),
      };
    }

    case SET_EDIT_BASKET_BASE_ROUTE: {
      return {
        ...state,
        errorMessage: undefined,
        errorMessageId: undefined,
        errorItemId: undefined,
        shouldHideRewardsValue: true,
      };
    }

    case `${PUT_ITEMS_QUANTITIES}.SUCCESS`: {
      const orderForm = get(action, 'result.orderForm', {});
      const amounts = orderForm.amounts;
      const items = orderForm.items;

      return {
        ...state,
        amounts,
        items,
        orderNeedsToBeRebatched: true,
        outOfStockItems: getOutOfStockStockItems(orderForm),
        limitedStockItems: getLimitedStockItems(orderForm),
      };
    }

    case `${PUT_ITEM_QUANTITY}.FAILED`: {
      const { itemId } = action;
      return {
        ...state,
        putItemQuantityApiCallActive: false,
        editBasketItemUpdateActiveIds: state.editBasketItemUpdateActiveIds.filter(id => id !== itemId),
      };
    }

    case HANDLE_EDIT_BASKET_QUANTITY_ERROR: {
      const { itemId, errorMessage } = action;
      return {
        ...state,
        errorMessage,
        errorMessageId: `${itemId}-edit-basket-error`,
        errorItemId: itemId,
      };
    }

    case `${PUT_ITEMS_QUANTITIES}.FAILED`: {
      return {
        ...state,
        amendItemsFailed: true,
        amendQuantitiesApiCallActive: false,
      };
    }

    case `${REBATCH_ORDER}.LOADING`:
      return {
        ...state,
        rebatchOrderApiCallActive: true,
      };

    case `${REBATCH_ORDER}.FAILED`:
      return {
        ...state,
        rebatchOrderApiCallActive: false,
      };

    case `${REBATCH_ORDER}.SUCCESS`: {
      const orderForm = get(action, 'result.orderForm', {});
      const activePayments = state.payments?.filter(payment => !payment.isRemoved);

      return {
        ...state,
        rebatchOrderApiCallActive: false,
        outOfStockItems: getOutOfStockStockItems(orderForm),
        limitedStockItems: getLimitedStockItems(orderForm),
        amendQuantitiesApiCallActive: false,
        orderNeedsToBeRebatched: false,
        items: orderForm.items || state.items,
        payments: activePayments || [],
        shouldApplyRewards: !state.customerRemovedRewards,
      };
    }

    case `${GET_ITEMS}.LOADING`:
      return {
        ...state,
        getItemsApiCallActive: true,
      };

    case `${GET_ITEMS}.FAILED`:
      return {
        ...state,
        getItemsApiCallActive: false,
      };

    case `${GET_ITEMS}.SUCCESS`: {
      const { isAgeVerification } = action;
      const orderForm = action?.result?.orderForm;

      const amounts = orderForm?.amounts;
      const items = orderForm?.items;
      const tradeInQuotes = orderForm?.tradeInQuotes;

      const itemIds = state.editBasketItemsLastRemovedIds;
      const orderNeedsToBeRebatched = isAgeVerification ?
        state.orderNeedsToBeRebatched :
        haveItemQuantitiesChanged(state.editBasketStartItemQuantities, items);

      return {
        ...state,
        amounts,
        items,
        getItemsApiCallActive: false,
        editBasketItemRemoveOverlayIds: state.editBasketItemRemoveOverlayIds.filter(
          id => !itemIds.includes(id),
        ),
        editBasketItemOutOfStockOverlayIds: state.editBasketItemOutOfStockOverlayIds.filter(
          id => !itemIds.includes(id),
        ),
        editBasketRemoveItemApiCallActiveIds: state.editBasketRemoveItemApiCallActiveIds.filter(
          id => !itemIds.includes(id),
        ),
        editBasketItemsLastRemovedIds: [],
        orderNeedsToBeRebatched,
        promoCodeApplied: get(action, 'result.orderForm.appliedPromotion'),
        tradeInQuotes,
      };
    }

    case `${REMOVE_BASKET_ITEMS}.LOADING`: {
      const { itemIds } = action;

      return {
        ...state,
        editBasketRemoveItemApiCallActiveIds: union(state.editBasketRemoveItemApiCallActiveIds, itemIds),
        removeBasketItemsApiCallActive: true,
      };
    }

    case `${REMOVE_BASKET_ITEMS}.FAILED`: {
      const { itemIds } = action;

      return {
        ...state,
        editBasketRemoveItemApiCallActiveIds: state.editBasketRemoveItemApiCallActiveIds.filter(
          id => !itemIds.includes(id),
        ),
        removeBasketItemsApiCallActive: false,
        removeBasketItemsFailed: true,
      };
    }

    case `${REMOVE_BASKET_ITEMS}.SUCCESS`: {
      const { itemIds, rebatchNotNeeded = false } = action;
      return {
        ...state,
        removeBasketItemsApiCallActive: false,
        removeBasketItemsFailed: false,
        orderNeedsToBeRebatched: !rebatchNotNeeded,
        editBasketItemsLastRemovedIds: itemIds,
        unavailableItemInfo: undefined,
      };
    }

    case SHOW_ITEM_REMOVE_OVERLAY: {
      const { itemId } = action;
      return {
        ...state,
        editBasketItemRemoveOverlayIds: union(state.editBasketItemRemoveOverlayIds, [itemId]),
      };
    }

    case HIDE_ITEM_REMOVE_OVERLAY: {
      const { itemId } = action;
      return {
        ...state,
        editBasketItemRemoveOverlayIds: state.editBasketItemRemoveOverlayIds.filter(id => id !== itemId),
      };
    }

    case BASKET_ITEM_OUT_OF_STOCK: {
      const { itemId } = action;
      return {
        ...state,
        editBasketItemOutOfStockOverlayIds: union(state.editBasketItemOutOfStockOverlayIds, [itemId]),
      };
    }

    case ROUTER_LOCATION_CHANGE: {

      const pathname = get(action.payload, 'location.pathname', '');

      if (pathname === routeConstants.EDIT_BASKET) {
        return {
          ...state,
          editBasketStartItemQuantities: getItemQuantities(state.items),
        };
      }

      return {
        ...state,
        editBasketItemRemoveOverlayIds: [],
        editBasketStartItemQuantities: undefined,
      };
    }

    case SHOW_EMPTY_BASKET_NOTIFICATION:
      return {
        ...state,
        orderNeedsToBeRebatched: false,
      };

    case SHOW_POS_CREDIT_BASKET_AMEND_MODAL: {
      const posCreditIneligibleItemIds = get(get(action, 'basketAmendCreditTypeGroup.requiredActions', [])?.find(recordItem => recordItem.type === requiredActionTypes.REMOVE_ITEMS), 'itemIdsToRemove', []);
      const allItems = flatMap(get(state, 'items', []), item => [item, ...(item?.childItems ?? [])]);
      const posCreditIneligibleItems = allItems?.filter(item => posCreditIneligibleItemIds.includes(item.id));

      return {
        ...state,
        basketAmendCreditTypeGroup: get(action, 'basketAmendCreditTypeGroup', {}),
        posCreditIneligibleItems,
        posCreditIneligibleItemIds,
        showPOSCreditBasketAmendModal: true,
      };
    }

    case HIDE_POS_CREDIT_BASKET_AMEND_MODAL:
      return {
        ...state,
        basketAmendCreditTypeGroup: undefined,
        posCreditIneligibleItemIds: undefined,
        posCreditIneligibleItems: undefined,
        showPOSCreditBasketAmendModal: false,
      };

    case REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET:
      return {
        ...state,
        orderNeedsToBeRebatched: true,
      };

    case CLOSE_EDIT_BASKET_MODAL: {
      const haveItemsChanged = haveItemQuantitiesChanged(state.editBasketStartItemQuantities, state.items);
      if (!haveItemsChanged) {
        return {
          ...state,
          shouldHideRewardsValue: false,
        };
      }
      return state;
    }

    default:
      return state;
  }
}
